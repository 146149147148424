import { Typography, Box } from '@mui/material';
import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import AppNav from '../../../../../../components/Layout/AppNav/components';
import NavContent from '../../../../../../components/Layout/AppNav/components/NavContent';
import NavHeader from '../../../../../../components/Layout/NavHeader';
import { useOurCompany } from '../../../../../../graphql/Company/operations/useOurCompany';
import { usePackingSessions } from '../../../../../../graphql/PackingSession/operations/usePackingSessions';
import { useProfileContext } from '../../../../../../providers/ProfileProvider';
import LaunchSession from './components/LaunchSession';
import SessionPreview from './components/SessionPreview';

const PalletizingLanding = (): ReactElement => {
    const {
        data: pData,
        error: pError,
        loading: pLoading,
    } = useProfileContext();

    const { data, error, loading } = usePackingSessions({
        variables: {
            filter: {
                skip: 0,
                take: 1,
                created_by: pData ? pData.profile.user_id : '',
                completed: false,
            },
        },
        skip: !pData,
    });

    const { data: cData, error: cError, loading: cLoading } = useOurCompany();

    const activeSession = data ? data.packingSessions.items[0] || null : null;

    return (
        <AppNav
            loading={loading || pLoading || cLoading}
            error={error || pError || cError}
        >
            <NavContent>
                {{
                    header: (
                        <NavHeader>
                            <Typography variant="crisp">Palletizing</Typography>
                            <Box></Box>
                        </NavHeader>
                    ),
                    content:
                        loading || pLoading ? (
                            <Box />
                        ) : activeSession ? (
                            <SessionPreview>{activeSession}</SessionPreview>
                        ) : cData ? (
                            <LaunchSession company={cData.ourCompany} />
                        ) : (
                            <Box />
                        ),
                }}
            </NavContent>
        </AppNav>
    );
};

export default PalletizingLanding;
