import {
    ButtonBase,
    Box,
    Typography,
    useTheme,
    Collapse,
    SxProps,
    Theme,
    useMediaQuery,
    hexToRgb,
    Tooltip,
} from '@mui/material';
import React, { ReactElement } from 'react';
import { MdExpandMore } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { getUiPermissions } from '../../../../../../../../../auth/UiPermission';
import useMyProfile from '../../../../../../../../../auth/useMyProfile';
import { UserRole } from '../../../../../../../../../auth/UserRole';
import { useThemeContext } from '../../../../../../../../../providers/AppThemeProvider';
import Anima from '../../../../../../../Anima';
import { AppNavRoute } from '../../../../../../routes';

export interface NavChipProps {
    label: string;
    data: AppNavRoute;
    open: boolean;
    setOpen: (open: boolean) => void;
    focus: string;
    setFocus: (key: string) => void;
    routeKey: string;
}

const NavChip = (props: NavChipProps): ReactElement => {
    const {
        label,
        data: route,
        open: parentOpen,
        setOpen: setParentOpen,
        focus,
        setFocus,
        routeKey,
    } = props;
    const nav = useNavigate();

    const open = focus == routeKey;

    const { permissions, roles } = useMyProfile();

    const { mode } = useThemeContext();

    const parentActive = route.children
        .map((s) => s.path)
        .some((link) => window.location.pathname.includes(link));

    const { palette, shape, spacing, breakpoints } = useTheme();
    const small = useMediaQuery(breakpoints.down('sm'));

    const getPermissibleChildren = (): AppNavRoute['children'] => {
        const res: AppNavRoute['children'] = [];

        for (const child of route.children) {
            if (child.auth == null) res.push(child);
            else if (child.auth.type == 'role') {
                if (roles.includes(child.auth.role)) res.push(child);
            } else {
                if (
                    roles.includes(UserRole.Admin) ||
                    roles.includes(UserRole.Manager) ||
                    getUiPermissions(permissions)
                        .map((p) => p.name)
                        .includes(child.auth.permission)
                )
                    res.push(child);
            }
        }
        return res.sort((a, b) => (a.label > b.label ? 1 : -1));
    };

    const children = getPermissibleChildren();

    const onClick = () => {
        if (children.length > 1) {
            setParentOpen(true);
            setFocus(routeKey == focus ? '' : routeKey);
        } else if (children[0]) {
            nav(children[0].path);
        }
    };

    const activeStyles: SxProps<Theme> =
        mode == 'dark'
            ? {
                  background: palette.action.hover,
                  color: palette.primary.main,
                  fontWeight: 900,
              }
            : {
                  background: hexToRgb(palette.primary.main).replace(
                      ')',
                      ', .1)'
                  ),
                  color: palette.primary.main,
              };

    if (children.length == 0) return <React.Fragment />;

    return (
        <React.Fragment>
            <ButtonBase
                onClick={onClick}
                sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'flex-start',
                    color: 'textSecondary',
                    ...shape,
                    p: small ? 2 : 1,
                    alignItems: 'center',
                    ...(parentActive
                        ? activeStyles
                        : { color: palette.text.secondary }),
                }}
            >
                <Tooltip
                    title={parentOpen ? '' : label}
                    arrow
                    placement="right"
                >
                    <Box sx={{ display: 'flex' }}>{route.icon}</Box>
                </Tooltip>
                <Box sx={{ width: spacing(1.5) }} />
                <Collapse in={parentOpen} orientation="horizontal">
                    <Box sx={{ display: 'flex' }}>
                        <Typography
                            sx={{
                                fontWeight: 500,
                                textAlign: 'left',
                                whiteSpace: 'nowrap',
                            }}
                        >
                            {label}
                        </Typography>
                        <Box sx={{ width: spacing(small ? 12 : 3) }} />
                        <Box sx={{ flex: 1 }} />
                        {children.length > 1 && (
                            <Anima in={open} type="rotate">
                                <Box
                                    sx={{ display: 'flex', fontSize: '1.5rem' }}
                                >
                                    <MdExpandMore />
                                </Box>
                            </Anima>
                        )}
                    </Box>
                </Collapse>
            </ButtonBase>
            <Box sx={{ width: '100%' }}>
                {children.length > 1 && (
                    <Collapse in={open && parentOpen}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexFlow: 'column',
                                gap: 1,
                                alignItems: 'flex-start',
                                width: '100%',
                                paddingLeft: 6,
                                paddingBottom: 2,
                                paddingTop: small ? 2 : 1,
                            }}
                        >
                            {children.map(({ label, path }, index) => (
                                <ButtonBase
                                    onClick={() => nav(path)}
                                    sx={{
                                        p: small ? 1 : 0.25,
                                        paddingLeft: small ? 2 : 1,
                                        paddingRight: small ? 2 : 1,
                                        ...shape,
                                        ...(window.location.pathname.includes(
                                            path
                                        )
                                            ? {
                                                  ...activeStyles,
                                                  background: undefined,
                                              }
                                            : {
                                                  color: palette.text.secondary,
                                              }),
                                    }}
                                    key={label + '_' + index}
                                >
                                    <Typography sx={{ fontWeight: 500 }}>
                                        {label}
                                    </Typography>
                                </ButtonBase>
                            ))}
                        </Box>
                    </Collapse>
                )}
            </Box>
        </React.Fragment>
    );
};

export default NavChip;
