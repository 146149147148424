import { LoadingButton } from '@mui/lab';
import { Box, TextField, useTheme } from '@mui/material/';
import ButtonGroup from '@mui/material/ButtonGroup';
import Typography from '@mui/material/Typography';
import React, { ReactElement } from 'react';
import { MdChevronRight } from 'react-icons/md';
import { BatchFocus } from '../..';
import ResponsiveDialog from '../../../../../../../../../../../../components/Layout/ResponsiveDialog';
import { Batch } from '../../../../../../../../../../../../graphql/Batch/Batch';
import {
    useBatchUpdate,
    UpdateBatchArgs,
} from '../../../../../../../../../../../../graphql/Batch/operations/useBatchUpdate';
import { RecipeVersion } from '../../../../../../../../../../../../graphql/RecipeVersion/RecipeVersion';
import { ScanType } from '../../../../../../../../../../../../graphql/Scan/ScanType';
import {
    ScanLot,
    useScanLots,
} from '../../../../../../../../../../../../graphql/Scan/useScanLots';
import { useScanner } from '../../../../../../../../../../../../hooks/useScanner';
import { useSnackbar } from '../../../../../../../../../../../../providers/SnackbarProvider';
import { getStateFromBatch } from '../../../../../../../BatchEntry';

export interface BatchContentScanProps {
    batch: Batch;
    recipe: RecipeVersion;
    focus: BatchFocus;
}

const BatchContentScan = (props: BatchContentScanProps): ReactElement => {
    const { batch, recipe, focus } = props;
    const { setSnackbar } = useSnackbar();

    const [search, setSearch] = React.useState('');

    const { palette } = useTheme();

    const [prompt, setPrompt] = React.useState<{
        lots: ScanLot[];
        type: ScanType;
        identifier: string;
    } | null>(null);

    const alert = (error: Error) => {
        setSnackbar({
            open: true,
            variant: 'error',
            message: error.message,
        });
    };

    const [updateBatch, { loading: batchLoading }] = useBatchUpdate({
        onError: (err) => {
            alert(err);
        },
        fetchPolicy: 'network-only',
    });

    const handleUpdate = (data: UpdateBatchArgs['data']) => {
        updateBatch({
            variables: {
                id: batch._id,
                data,
            },
        });
    };

    const handleLotEntry = (
        lot: ScanLot,
        type: ScanType,
        identifider: string
    ) => {
        if (!focus) {
            //
        } else if (focus.type == 'crumb') {
            const existing = batch.lines.filter(
                (l) => l.recipe_step == null && l.content.lot._id == lot._id
            );
            if (existing.length > 0)
                alert(new Error(`Lot is already included as crumb.`));
            else {
                const data = getStateFromBatch(batch);
                data.lines.push({
                    recipe_step: null,
                    content: {
                        lot: lot._id,
                        quantity: 0,
                        unit: null,
                        receipt_line:
                            type == ScanType.ReceiptLine ? identifider : null,
                    },
                });
                handleUpdate(data);
            }
        } else if (focus.type == 'section') {
            const validStepIndex = focus.section.steps.findIndex((step) => {
                if (!step.content) return false;
                const itemIds = step.content.items.map((i) => i._id);
                if (itemIds.includes(lot.item._id)) return true;
                else return false;
            });

            if (validStepIndex == -1)
                alert(
                    new Error(
                        `No ${lot.item.name} is included in this section.`
                    )
                );
            else {
                const step = focus.section.steps[validStepIndex];
                if (!step.content) {
                    alert(
                        new Error(
                            `No ${lot.item.name} is included in this section.`
                        )
                    );
                } else {
                    const data = getStateFromBatch(batch);
                    data.lines.push({
                        recipe_step: focus.section.steps[validStepIndex]._id,
                        content: {
                            lot: lot._id,
                            quantity: step.content.client_qty,
                            unit: step.content.client_unit._id,
                            receipt_line:
                                type == ScanType.ReceiptLine
                                    ? identifider
                                    : null,
                        },
                    });
                    handleUpdate(data);
                }
            }
        } else {
            // step scan
            if (!focus.step.content)
                alert(new Error('Step has no ingredients.'));
            else if (
                batch.lines.filter(
                    (l) =>
                        l.recipe_step === focus.step._id &&
                        l.content.lot._id == lot._id
                ).length > 0
            ) {
                alert(new Error('Lot is already in step.'));
            } else {
                const items = focus.step.content.items;

                if (items.map((i) => i._id).includes(lot.item._id)) {
                    const data = getStateFromBatch(batch);
                    const siblings = [
                        ...data.lines.filter(
                            (l) => l.recipe_step == focus.step._id
                        ),
                    ];

                    data.lines = data.lines.filter(
                        (l) => l.recipe_step !== focus.step._id
                    );

                    data.lines = [
                        ...data.lines,
                        ...siblings.map((s) => ({
                            ...s,
                            content: {
                                ...s.content,
                                quantity: focus.step.content
                                    ? focus.step.content.client_qty /
                                      (siblings.length + 1)
                                    : 0,
                            },
                        })),
                    ];

                    data.lines.push({
                        recipe_step: focus.step._id,
                        content: {
                            lot: lot._id,
                            quantity:
                                focus.step.content.client_qty /
                                (siblings.length + 1),
                            unit: focus.step.content.client_unit._id,
                            receipt_line:
                                type == ScanType.ReceiptLine
                                    ? identifider
                                    : null,
                        },
                    });
                    handleUpdate(data);
                } else {
                    alert(
                        new Error(
                            `${lot.item.name} is not valid for this step.`
                        )
                    );
                }
            }
        }
    };

    const handleLotsResponse = (data: {
        lots: ScanLot[];
        type: ScanType;
        identifier: string;
    }) => {
        if (data.lots.length == 0) alert(new Error('No lots found.'));
        else if (data.lots.length > 1) {
            setPrompt(data);
        } else {
            const lot = data.lots[0];
            handleLotEntry(lot, data.type, data.identifier);
        }
    };

    const [handleScan, { loading: scanLoading }] = useScanLots({
        onComplete: (data) => {
            if (!batchLoading) handleLotsResponse(data);
        },
        onError: (error) => {
            alert(error);
        },
    });

    useScanner({
        onScan: (code) => {
            handleScan(code.toString());
        },
    });

    const loading = scanLoading || batchLoading;

    return (
        <React.Fragment>
            <Box
                sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    paddingBottom: 4,
                    paddingLeft: 4,
                }}
            >
                <TextField
                    label="Search for a lot"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key == 'Enter') {
                            handleScan(search);
                        }
                    }}
                />
            </Box>
            <ResponsiveDialog
                PaperProps={{ sx: { background: palette.background.default } }}
                open={Boolean(prompt)}
                onClose={() => setPrompt(null)}
            >
                <Typography variant="h6">Which lot did you scan?</Typography>
                <Box p={1} />
                <ButtonGroup
                    size="large"
                    variant="contained"
                    fullWidth
                    orientation="vertical"
                    color="success"
                >
                    {(prompt ? prompt.lots : []).map((lot, index) => (
                        <LoadingButton
                            loading={loading}
                            variant="contained"
                            color="success"
                            key={'prompt_ ' + index}
                            sx={{ justifyContent: 'space-between' }}
                            onClick={() => {
                                if (prompt) {
                                    handleLotEntry(
                                        lot,
                                        prompt.type,
                                        prompt.identifier
                                    );
                                    setPrompt(null);
                                }
                            }}
                        >
                            <Box sx={{ textAlign: 'left' }}>
                                <Typography variant="body2">
                                    {lot.code}
                                </Typography>
                                <Typography variant="caption">
                                    {lot.item.name}
                                </Typography>
                            </Box>
                            <Box>
                                <MdChevronRight />
                            </Box>
                        </LoadingButton>
                    ))}
                </ButtonGroup>
            </ResponsiveDialog>
        </React.Fragment>
    );
};

export default BatchContentScan;
