import { gql } from '@apollo/client';
import { Pagination } from '../../../utils/types/Pagination';
import { BaseFragment } from '../../Base/Base';
import { getQueryHook } from '../../types';
import {
    PackingSession,
    PackingSessionFragment,
    PackingSessionLotFragment,
} from '../PackingSession';
import { PackingSessionFilter } from '../PackingSessionFilter';

export const PackingSessionsQuery = gql`
    ${BaseFragment}
    ${PackingSessionFragment}
    ${PackingSessionLotFragment}
    query PackingSessionsQuery($filter: PackingSessionFilter!) {
        packingSessions(filter: $filter) {
            count
            items {
                ...PackingSessionFragment
            }
        }
    }
`;

export interface PackingSessionsRes {
    packingSessions: Pagination<PackingSession>;
}

export interface PackingSessionsArgs {
    filter: PackingSessionFilter;
}

export const usePackingSessions = getQueryHook<
    PackingSessionsRes,
    PackingSessionsArgs
>(PackingSessionsQuery);
