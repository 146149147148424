import { Box, TextField, Typography } from '@mui/material';
import React, { ReactElement } from 'react';
import { MdCheck, MdDelete, MdRefresh } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import FormResult from '../../../../../../components/feedback/FormResult';
import CarefullButton from '../../../../../../components/inputs/Buttons/CarefulButton';
import SuccessButton from '../../../../../../components/inputs/Buttons/SuccessButton';
import QtyInput from '../../../../../../components/inputs/QtyInput';
import AppNav from '../../../../../../components/Layout/AppNav/components';
import NavContent from '../../../../../../components/Layout/AppNav/components/NavContent';
import FormRow from '../../../../../../components/Layout/FormRow';
import NavHeader from '../../../../../../components/Layout/NavHeader';
import { CreateProductInput } from '../../../../../../graphql/Item/extensions/Product/inputs/CreateProductInput';
import { useProduct } from '../../../../../../graphql/Item/extensions/Product/operations/useProduct';
import {
    CreateProductArgs,
    CreateProductRes,
    useProductCreation,
} from '../../../../../../graphql/Item/extensions/Product/operations/useProductCreation';
import {
    UpdateProductArgs,
    UpdateProductRes,
    useProductUpdate,
} from '../../../../../../graphql/Item/extensions/Product/operations/useProductUpdate';
import { Product } from '../../../../../../graphql/Item/extensions/Product/Product';
import { OperationResult } from '../../../../../../utils/types/OperationResult';

const defultProduct: CreateProductInput = {
    name: '',
    spanish_name: '',
    conversions: [],
    upc: '',
    sku: '',
    boxes_per_case: null,
    cases_per_pallet: null,
};

const getProductUpdateData = (product: Product): UpdateProductArgs => ({
    id: product._id,
    data: {
        name: product.name,
        spanish_name: product.spanish_name,
        conversions: product.conversions.map(({ to, multiplier }) => ({
            to,
            multiplier,
        })),
        upc: product.upc,
        sku: product.sku,
        boxes_per_case: product.boxes_per_case,
        cases_per_pallet: product.cases_per_pallet,
    },
});

const ProductForm = (): ReactElement => {
    const { id } = useParams();
    const nav = useNavigate();

    const [state, setState] = React.useState<
        UpdateProductArgs | CreateProductArgs
    >({
        data: defultProduct,
    });

    const { data } = useProduct({
        variables: { id: id || '' },
        skip: !id,
        fetchPolicy: 'network-only',
        onCompleted: ({ product }) => {
            setState(getProductUpdateData(product));
        },
    });

    const [result, setResult] = React.useState<null | OperationResult<
        CreateProductRes | UpdateProductRes
    >>(null);

    const [handleCreate, { loading: createLoading }] = useProductCreation({
        variables: 'id' in state ? undefined : state,
        onCompleted: (data) => setResult({ success: true, data }),
        onError: (error) => setResult({ success: false, error }),
    });

    const [handleUpdate, { loading: updateLoading }] = useProductUpdate({
        variables:
            'id' in state ? { ...state, id: id || state.id || '' } : undefined,
        onCompleted: (data) => setResult({ success: true, data }),
        onError: (error) => setResult({ success: false, error }),
    });

    const getHoldup = (): string | null => {
        if (!state.data.name) return 'Please enter a product name.';
        if (!state.data.spanish_name) return 'Please enter a spanish name.';
        if (!state.data.upc) return 'Please enter a UPC';
        if (!state.data.sku) return 'Please enter a SKU';
        return null;
    };

    return (
        <AppNav loading={updateLoading || createLoading}>
            {result ? (
                <FormResult
                    entity="Product"
                    clear={() => setResult(null)}
                    onComplete={() => nav('/library/products')}
                >
                    {result}
                </FormResult>
            ) : (
                <NavContent>
                    {{
                        header: (
                            <NavHeader
                                back={
                                    data
                                        ? [
                                              data.product.name,
                                              `/library/products/${data.product._id}`,
                                          ]
                                        : ['Products', '/library/products']
                                }
                            >
                                <Box>
                                    <Typography variant="crisp">
                                        {id && !data
                                            ? ''
                                            : data
                                            ? `Update ${data.product.name}`
                                            : 'New Product'}
                                    </Typography>
                                    <Typography
                                        sx={{ paddingTop: 1 }}
                                        color="textSecondary"
                                    >
                                        Product
                                    </Typography>
                                </Box>
                                <Box />
                            </NavHeader>
                        ),
                        content: (
                            <Box sx={{ maxWidth: 400 }}>
                                <FormRow>
                                    <TextField
                                        autoFocus
                                        fullWidth
                                        label="Name"
                                        value={state.data.name}
                                        onChange={(e) =>
                                            setState({
                                                ...state,
                                                data: {
                                                    ...state.data,
                                                    name: e.target.value,
                                                },
                                            })
                                        }
                                    />
                                </FormRow>
                                <FormRow>
                                    <TextField
                                        fullWidth
                                        label="Spanish"
                                        value={state.data.spanish_name}
                                        onChange={(e) =>
                                            setState({
                                                ...state,
                                                data: {
                                                    ...state.data,
                                                    spanish_name:
                                                        e.target.value,
                                                },
                                            })
                                        }
                                    />
                                </FormRow>
                                <FormRow>
                                    <TextField
                                        fullWidth
                                        label="UPC"
                                        value={state.data.upc}
                                        onChange={(e) =>
                                            setState({
                                                ...state,
                                                data: {
                                                    ...state.data,
                                                    upc: e.target.value,
                                                },
                                            })
                                        }
                                    />
                                    <TextField
                                        fullWidth
                                        label="SKU"
                                        value={state.data.sku}
                                        onChange={(e) =>
                                            setState({
                                                ...state,
                                                data: {
                                                    ...state.data,
                                                    sku: e.target.value,
                                                },
                                            })
                                        }
                                    />
                                </FormRow>
                                <FormRow>
                                    <QtyInput
                                        int
                                        label="Boxes per Case"
                                        value={state.data.boxes_per_case}
                                        onChange={(boxes_per_case) => {
                                            setState({
                                                ...state,
                                                data: {
                                                    ...state.data,
                                                    boxes_per_case,
                                                },
                                            });
                                        }}
                                    />
                                    <QtyInput
                                        int
                                        label="Cases per Pallet"
                                        value={state.data.cases_per_pallet}
                                        onChange={(cases_per_pallet) => {
                                            setState({
                                                ...state,
                                                data: {
                                                    ...state.data,
                                                    cases_per_pallet,
                                                },
                                            });
                                        }}
                                    />
                                </FormRow>
                                <FormRow>
                                    <SuccessButton
                                        holdup={getHoldup()}
                                        success={null}
                                        onSuccess={() => null}
                                        endIcon={<MdCheck />}
                                        onClick={() => {
                                            if ('id' in state) {
                                                handleUpdate();
                                            } else {
                                                handleCreate();
                                            }
                                        }}
                                    >
                                        Save
                                    </SuccessButton>
                                    {data && (
                                        <CarefullButton
                                            endIcon={
                                                data.product.deletion ? (
                                                    <MdRefresh />
                                                ) : (
                                                    <MdDelete />
                                                )
                                            }
                                            onClick={() => {
                                                handleUpdate({
                                                    variables: {
                                                        id: data.product._id,
                                                        data: {
                                                            ...getProductUpdateData(
                                                                data.product
                                                            ).data,
                                                            deleted: data
                                                                .product
                                                                .deletion
                                                                ? false
                                                                : true,
                                                        },
                                                    },
                                                    onCompleted: (data) =>
                                                        setResult({
                                                            success: true,
                                                            data,
                                                        }),
                                                    onError: (error) =>
                                                        setResult({
                                                            success: false,
                                                            error,
                                                        }),
                                                });
                                            }}
                                        >
                                            {data.product.deletion
                                                ? 'Restore Product'
                                                : 'Delete Product'}
                                        </CarefullButton>
                                    )}
                                </FormRow>
                            </Box>
                        ),
                    }}
                </NavContent>
            )}
        </AppNav>
    );
};

export default ProductForm;
