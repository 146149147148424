import React from 'react';
import { ApolloError } from '@apollo/client';
import { useLazyLots } from '../Lot/useLots';
import { useLazyReceiptLine } from '../ReceiptLine/ReceiptLine';
import { useScan } from './useScan';
import { ScanType } from './ScanType';
import { ScanResult } from './ScanResult';

export type ScanLot = {
    _id: string;
    code: string;
    item: { _id: string; name: string };
    company: { _id: string; name: string };
    qty: number;
};

export const useScanLots = (props: {
    onComplete: (data: {
        lots: ScanLot[];
        type: ScanType;
        identifier: string;
    }) => void;
    onError: (error: ApolloError) => void;
}): [
    handleScan: (code: string) => void,
    result: {
        loading: boolean;
    }
] => {
    const { onComplete, onError } = props;

    const [getLots, { loading: lotLoading, error: lotError }] = useLazyLots({
        onError: (err) => onError(err),
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
    });

    const [getReceiptLine, { loading: receiptLoading, error: receiptError }] =
        useLazyReceiptLine({
            onError: (err) => onError(err),
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
        });

    const scanResultHandler: Record<ScanType, (result: ScanResult) => void> = {
        [ScanType.Lot]: (res) =>
            getLots({
                variables: {
                    filter: {
                        skip: 0,
                        take: 100,
                        ids: [res.identifier],
                    },
                },
                onError: (err) => onError(err),
                onCompleted: ({ lots: { items } }) => {
                    onComplete({ lots: items, ...res });
                },
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            }),
        [ScanType.ReceiptLine]: (res) =>
            getReceiptLine({
                variables: {
                    id: res.identifier,
                },
                onError: (err) => onError(err),
                onCompleted: ({ receipt_line }) => {
                    onComplete({
                        lots: receipt_line.line.lots.map((l) => l.lot),
                        ...res,
                    });
                },
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            }),
        [ScanType.Bol]: (res) => null,
        [ScanType.Text]: (res) =>
            getLots({
                variables: {
                    filter: {
                        skip: 0,
                        take: 100,
                        code: res.identifier,
                    },
                },
                onError: (err) => onError(err),
                onCompleted: ({ lots: { items } }) => {
                    onComplete({ lots: items, ...res });
                },
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            }),
    };

    const [handleScan, { loading: scanLoading, error: scanError }] = useScan({
        onCompleted: ({ scan }) => {
            scanResultHandler[scan.type](scan);
        },
        onError: (err) => onError(err),
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
    });

    return [
        (code) => handleScan({ variables: { code } }),
        {
            loading: scanLoading || lotLoading || receiptLoading,
        },
    ];
};
