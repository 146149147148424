import { useTheme, Box, Button } from '@mui/material/';
import React, { ReactElement } from 'react';
import { MdExitToApp } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import Spanish from '../../../../../../../../components/display/Spanish';
import SpanishToggle from '../../../../../../../../components/inputs/SpanishToggle';
import ThemeToggle from '../../../../../../../../components/inputs/ThemeToggle';

const PalletizingTopBar = (): ReactElement => {
    const { palette, shadows } = useTheme();
    const nav = useNavigate();

    return (
        <Box
            sx={{
                p: 1.5,
                background: palette.background.paper,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                boxShadow: shadows[4],
                zIndex: 10,
            }}
        >
            <Spanish
                variant="crisp"
                sx={{ fontSize: '1.7rem', paddingLeft: 1 }}
            >
                Palletizing
            </Spanish>
            <Box sx={{ display: 'flex', alignItems: 'stretch', gap: 2 }}>
                <Box>
                    <ThemeToggle />
                </Box>
                <SpanishToggle />
                <Button
                    onClick={() => nav('/production/palletizing')}
                    endIcon={<MdExitToApp />}
                    color="inherit"
                >
                    Exit session
                </Button>
            </Box>
        </Box>
    );
};

export default PalletizingTopBar;
