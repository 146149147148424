import { LoadingButton } from '@mui/lab';
import { Typography, Box, useTheme, Button } from '@mui/material/';
import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import Spanish from '../../../../../../components/display/Spanish';
import NavContent from '../../../../../../components/Layout/AppNav/components/NavContent';
import NavHeader from '../../../../../../components/Layout/NavHeader';
import { useBatchLaunch } from '../../../../../../graphql/Batch/operations/useBatchLaunch';
import { MixingCard } from '../../../../../../graphql/MixingCard/MixingCard';

export interface MyCardProps {
    card: MixingCard | null;
}

const MyCard = (props: MyCardProps): ReactElement => {
    const { card } = props;

    const { palette } = useTheme();

    const nav = useNavigate();

    const [handleLaunch, { loading: launchLoading }] = useBatchLaunch({
        onCompleted: ({ startBatch: batch }) => {
            nav(batch._id);
        },
    });

    return (
        <Box
            sx={{
                height: '100%',
                display: 'flex',
                flexFlow: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            {card ? (
                <Box sx={{ background: palette.tonal, marginBottom: 8 }}>
                    <Box
                        sx={{
                            p: 2,
                            background: palette.background.paper,
                        }}
                    >
                        <Typography
                            sx={{
                                paddingRight: 12,
                            }}
                            variant="h4"
                        >
                            My Mixing Schedule
                        </Typography>
                    </Box>
                    <Box>
                        {card.lines.length > 0 ? (
                            <Box>
                                {card.lines.map((line, lineIndex) => (
                                    <Box
                                        key={'line_' + lineIndex}
                                        sx={{
                                            p: 2,
                                            display: 'flex',
                                            alignItems: 'center',
                                            borderBottom:
                                                lineIndex ==
                                                card.lines.length - 1
                                                    ? undefined
                                                    : `1px solid ${palette.divider}`,
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                paddingRight: 12,
                                            }}
                                        >
                                            <Typography variant="h6">
                                                {line.recipe.name}
                                            </Typography>
                                            <Typography color="text.secondary">
                                                {line.recipe.item.name}
                                            </Typography>
                                        </Box>
                                        <Box>
                                            {lineIndex == 0 ? (
                                                <LoadingButton
                                                    loading={launchLoading}
                                                    variant="contained"
                                                    onClick={() =>
                                                        handleLaunch()
                                                    }
                                                    size="large"
                                                    fullWidth
                                                >
                                                    lefts go!
                                                </LoadingButton>
                                            ) : (
                                                <Typography>
                                                    {line.qty == null
                                                        ? `Unit stopped`
                                                        : `${line.qty} batch${
                                                              line.qty == 1
                                                                  ? ''
                                                                  : 'es'
                                                          }`}
                                                </Typography>
                                            )}
                                        </Box>
                                    </Box>
                                ))}
                            </Box>
                        ) : (
                            <Box sx={{ p: 2 }}>
                                <Spanish sx={{ maxWidth: 300 }}>
                                    You have completed all batches on your
                                    schedule. Please see a supervisor to add
                                    more recipes.
                                </Spanish>
                            </Box>
                        )}
                    </Box>
                </Box>
            ) : (
                <Box></Box>
            )}
        </Box>
    );
};

export default MyCard;
