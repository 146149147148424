import {
    Avatar,
    Button,
    InputAdornment,
    Table,
    TableBody,
    TableHead,
    Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { ReactElement } from 'react';
import { MdAdd, MdSearch } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import SmartTable from '../../../../components/display/SmartTable';
import SearchInput from '../../../../components/inputs/FieldInputs/SearchInput';
import AppNav from '../../../../components/Layout/AppNav/components';
import NavContent from '../../../../components/Layout/AppNav/components/NavContent';
import {
    ProfileFilter,
    useProfiles,
} from '../../../../graphql/People/useProfiles';
import { useProfile } from '../../../../graphql/Profile/useProfile';
import ProfileDialog from './components/ProfileDialog';

export interface ProfilesProps {
    create?: true;
}

const Profiles = (props: ProfilesProps): ReactElement => {
    const { create } = props;
    const { id } = useParams();
    const nav = useNavigate();

    const [filter, setFilter] = React.useState<ProfileFilter>({
        skip: 0,
        take: 50,
    });

    const { data, error, loading } = useProfiles({
        variables: { filter },
    });

    return (
        <AppNav error={error} loading={loading}>
            <NavContent>
                {{
                    header: (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'flex-end',
                            }}
                        >
                            <Typography variant="crisp">Profiles</Typography>
                            <Button
                                onClick={() => nav('new')}
                                disabled={Boolean(create || id)}
                                startIcon={<MdAdd />}
                            >
                                New Profile
                            </Button>
                        </Box>
                    ),
                    content: (
                        <SmartTable
                            data={data ? data.profiles.items : []}
                            getProps={(d) => ({
                                id: d.user_id,
                                onClick: (d) => nav(d.user_id),
                            })}
                            pagination={{
                                count: data ? data.profiles.count : 0,
                                filter,
                                setFilter: (f) =>
                                    setFilter({ ...filter, ...f }),
                            }}
                            controls={{
                                Name: (
                                    <SearchInput
                                        variant="standard"
                                        placeholder="Name"
                                        InputProps={{
                                            disableUnderline: true,
                                        }}
                                        value={filter.name || ''}
                                        onChange={(val) => {
                                            setFilter({
                                                skip: 0,
                                                take: 50,
                                                name: val || '',
                                            });
                                        }}
                                    />
                                ),
                            }}
                        >
                            {{
                                [' ']: (d) => (
                                    <Avatar src={d.picture} alt={d.name} />
                                ),
                                Name: (d) => d.name,
                                ['Login via']: (d) =>
                                    d.username ? 'Username' : 'Email',
                                Username: (d) => d.username || '',
                                Email: (d) => (d.username ? '' : d.email || ''),
                                Status: (d) =>
                                    d.blocked ? 'Blocked' : 'Active',
                            }}
                        </SmartTable>
                    ),
                }}
            </NavContent>
            <ProfileDialog focus={create ? true : id || null} />
        </AppNav>
    );
};

export default Profiles;
