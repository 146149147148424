import { IconButton, Tooltip } from '@mui/material/';
import React, { ReactElement } from 'react';
import { MdDarkMode, MdLightMode } from 'react-icons/md';
import { useThemeContext } from '../../../providers/AppThemeProvider';

const ThemeToggle = (): ReactElement => {
    const { mode, setMode } = useThemeContext();
    return (
        <Tooltip
            placement="right"
            arrow
            title={mode == 'dark' ? 'Dark mode' : 'Light mode'}
        >
            <IconButton
                onClick={() => setMode(mode == 'dark' ? 'light' : 'dark')}
            >
                {mode == 'dark' ? <MdDarkMode /> : <MdLightMode />}
            </IconButton>
        </Tooltip>
    );
};

export default ThemeToggle;
