import { Box, Button, useTheme } from '@mui/material';
import React, { ReactElement } from 'react';
import { MdChevronLeft } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import AppNav from '../../../../../../components/Layout/AppNav/components';
import NavContent from '../../../../../../components/Layout/AppNav/components/NavContent';
import { useOrder } from '../../../../../../graphql/Order/operations/useOrder';
import OrderRender from './components/OrderRender';

const OrderDetail = (): ReactElement => {
    const { id, contact, location } = useParams();
    const nav = useNavigate();

    const { palette } = useTheme();

    const { data, error, loading } = useOrder({
        variables: { id: id || '' },
        fetchPolicy: 'network-only',
    });

    const order = data ? data.order : null;

    return (
        <AppNav error={error} loading={loading}>
            <NavContent>
                {{
                    content: order ? (
                        <Box sx={{ height: '100%', overflow: 'auto' }}>
                            <Box p={2} />
                            <Button
                                startIcon={<MdChevronLeft />}
                                variant="text"
                                color="inherit"
                                onClick={() => nav('/supplychain/orders')}
                            >
                                Orders
                            </Button>
                            <Box p={0.5} />
                            <OrderRender>{order}</OrderRender>
                            <Box p={6} />
                        </Box>
                    ) : (
                        <Box />
                    ),
                }}
            </NavContent>
        </AppNav>
    );
};

export default OrderDetail;
