import React, { ReactElement } from 'react';
import { Route, Routes } from 'react-router-dom';
import PalletizingForm from './components/PalletizingForm';
import PalletizingLanding from './components/PalletizingLanding';

const Palletizing = (): ReactElement => {
    return (
        <Routes>
            <Route index element={<PalletizingLanding />} />
            <Route path=":id" element={<PalletizingForm />} />
        </Routes>
    );
};

export default Palletizing;
