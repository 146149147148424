export enum ScanType {
    ReceiptLine = 'ReceiptLine',
    Lot = 'Lot',
    Bol = 'Bol',
    Text = 'Text',
}

export const ScanPrefix: Record<ScanType, string> = {
    [ScanType.ReceiptLine]: 'r-',
    [ScanType.Lot]: 'l-',
    [ScanType.Bol]: 'b-',
    [ScanType.Text]: 'ttt---',
};
