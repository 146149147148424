import React, { ReactElement } from 'react';
import { Route, Routes } from 'react-router-dom';
import QualityChecks from './components/QualityChecks';

const QualityAssurance = (): ReactElement => {
    return (
        <Routes>
            <Route path="qualitychecks/*" element={<QualityChecks />} />
        </Routes>
    );
};

export default QualityAssurance;
