import { gql } from '@apollo/client';
import { BaseFragment } from '../../Base/Base';
import { getAtomicQueryHook } from '../../types';
import {
    PackingSession,
    PackingSessionFragment,
    PackingSessionLotFragment,
} from '../PackingSession';

export const PackingSessionQuery = gql`
    ${PackingSessionFragment}
    ${BaseFragment}
    ${PackingSessionLotFragment}
    query PackingSessionQuery($id: ObjectId!) {
        packingSession(id: $id) {
            ...PackingSessionFragment
        }
    }
`;

export interface PackingSessionRes {
    packingSession: PackingSession;
}

export const usePackingSession =
    getAtomicQueryHook<PackingSessionRes>(PackingSessionQuery);
