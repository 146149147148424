import {
    Box,
    Button,
    ButtonBase,
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    Popover,
    Skeleton,
    Typography,
    useTheme,
} from '@mui/material';
import { format } from 'date-fns';
import React, { ReactElement } from 'react';
import { MdCheckCircle, MdChevronRight, MdEdit, MdMenu } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { WarehouseDashboardProps } from '../../../..';
import AuthGuy from '../../../../../../../../auth/components/AuthGuy';
import { UiPermission } from '../../../../../../../../auth/UiPermission';
import VerificationBadge from '../../../../../../../../components/display/VerificationBadge';
import Message from '../../../../../../../../components/feedback/Message';
import { useOrder } from '../../../../../../../../graphql/Order/operations/useOrder';
import { useClickState } from '../../../../../../../../hooks/useClickState';
import { dateFormats } from '../../../../../../../../utils/dateFormats';
import { longList } from '../../../../../../../../utils/longList';

export interface WarehousePopoverProps {
    focus: { bol: string; order: string; element: Element } | null;
    onClose: () => void;
    view: WarehouseDashboardProps['view'];
}

const WarehousePopover = (props: WarehousePopoverProps): ReactElement => {
    const { focus, view, onClose } = props;
    const nav = useNavigate();

    const [clickState, setClickState] = useClickState();

    const { palette, shape } = useTheme();

    const { data, error, loading } = useOrder({
        variables: focus ? { id: focus.order } : undefined,
        skip: !focus,
        fetchPolicy: 'network-only',
    });

    const order = data ? data.order : null;

    const bols = order ? order.itineraries.map((itin) => itin.bols).flat() : [];

    const bol = !focus ? null : bols.find((b) => b._id === focus.bol) || null;

    return (
        <Popover
            sx={{ zIndex: 1 }}
            open={Boolean(focus)}
            onClose={onClose}
            anchorEl={focus ? focus.element : null}
        >
            <Box sx={{ width: 360, minHeight: 160 }}>
                {loading ? (
                    <Box
                        sx={{
                            height: '100%',
                            display: 'flex',
                            flexFlow: 'column',
                            alignItems: 'flex-start',
                            padding: 2,
                        }}
                    >
                        <Skeleton width="100%" height={60} />
                        <Skeleton width="80%" height={32} />
                        <Skeleton width="80%" height={32} />
                    </Box>
                ) : error ? (
                    <Box>
                        <Message type="Error">{error.message}</Message>
                    </Box>
                ) : bol && order ? (
                    <Box>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                padding: 2,
                                paddingBottom: 0,
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1,
                                }}
                            >
                                <Typography variant="h6" color="textSecondary">
                                    PO#
                                </Typography>
                                <Typography variant="h6">
                                    {order.code}
                                </Typography>
                            </Box>
                            <AuthGuy
                                challenge={{
                                    permission: UiPermission.Logistics,
                                }}
                            >
                                <IconButton
                                    onClick={(event) =>
                                        setClickState({
                                            target: event.currentTarget,
                                        })
                                    }
                                >
                                    <MdMenu />
                                </IconButton>
                            </AuthGuy>
                            <Menu
                                open={Boolean(clickState)}
                                anchorEl={clickState ? clickState.target : null}
                                onClose={() => setClickState(null)}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                            >
                                <MenuItem
                                    onClick={() =>
                                        nav(`/supplychain/orders/${order._id}`)
                                    }
                                >
                                    <ListItemIcon>
                                        <MdEdit />
                                    </ListItemIcon>
                                    Edit Order
                                </MenuItem>
                            </Menu>
                        </Box>

                        <Box sx={{ p: 2, paddingTop: 0, paddingBottom: 0 }}>
                            <Typography color="textSecondary" variant="caption">
                                Contents
                            </Typography>
                            <Box>
                                {(bol ? bol.contents : []).map(
                                    (content, index) => (
                                        <Typography key={'content_' + index}>
                                            {`${content.item.name} - ${
                                                content.client_qty
                                            } ${
                                                content.client_unit[
                                                    content.client_qty == 1
                                                        ? 'name'
                                                        : 'plural'
                                                ]
                                            }`}
                                        </Typography>
                                    )
                                )}
                            </Box>
                        </Box>
                        <Box sx={{ paddingLeft: 2, paddingTop: 2 }}>
                            <Typography variant="caption" color="textSecondary">
                                {bol[
                                    view == 'Receiving'
                                        ? 'receipts'
                                        : 'shipments'
                                ].length == 0
                                    ? `This BOL does not have any ${
                                          view == 'Receiving'
                                              ? 'receipts'
                                              : 'shipments'
                                      } yet`
                                    : `This BOL has ${
                                          bol[
                                              view == 'Receiving'
                                                  ? 'receipts'
                                                  : 'shipments'
                                          ].length
                                      } ${
                                          (view == 'Receiving'
                                              ? 'receipt'
                                              : 'shipment') +
                                          (bol[
                                              view == 'Receiving'
                                                  ? 'receipts'
                                                  : 'shipments'
                                          ].length == 1
                                              ? ''
                                              : 's')
                                      }`}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                padding: 2,
                                paddingTop: 1,
                                display: 'flex',
                                gap: 1,
                                flexFlow: 'column',
                            }}
                        >
                            {bol[
                                view == 'Receiving' ? 'receipts' : 'shipments'
                            ].map((f, fIndex) => (
                                <ButtonBase
                                    onClick={() => nav(f._id)}
                                    key={'f_' + fIndex}
                                    sx={{
                                        padding: 1,
                                        background:
                                            palette.mode == 'dark'
                                                ? palette.background.paper
                                                : palette.action.hover,
                                        ...shape,
                                        flexFlow: 'row',
                                        gap: 1,
                                        justifyContent: 'flex-start',
                                        border: `1px solid ${palette.divider}`,
                                    }}
                                >
                                    <VerificationBadge fontSize="1.75rem">
                                        {f}
                                    </VerificationBadge>
                                    <Box sx={{ textAlign: 'left' }}>
                                        <Typography>
                                            {longList(
                                                f.lines.map((l) => l.item.name),
                                                1
                                            )}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                        >
                                            {`${
                                                view == 'Receiving'
                                                    ? 'Received by '
                                                    : 'Shipped by '
                                            }${f.created_by.name}`}
                                        </Typography>
                                        <Typography
                                            variant="caption"
                                            color="textSecondary"
                                        >
                                            {format(
                                                new Date(f.date_created),
                                                dateFormats.condensedDate
                                            )}
                                        </Typography>
                                    </Box>
                                </ButtonBase>
                            ))}
                            {bol && order && (
                                <Box>
                                    <Button
                                        sx={{ marginTop: 1 }}
                                        onClick={() => {
                                            nav(`form/${order._id}/${bol._id}`);
                                        }}
                                        variant={
                                            bol[
                                                view == 'Receiving'
                                                    ? 'receipts'
                                                    : 'shipments'
                                            ].length > 0
                                                ? 'outlined'
                                                : undefined
                                        }
                                        color={
                                            bol[
                                                view == 'Receiving'
                                                    ? 'receipts'
                                                    : 'shipments'
                                            ].length > 0
                                                ? 'inherit'
                                                : undefined
                                        }
                                        endIcon={<MdChevronRight />}
                                    >
                                        {view == 'Receiving'
                                            ? bol.receipts.length > 0
                                                ? 'Add another receipt'
                                                : 'Create Receipt'
                                            : bol.shipments.length > 0
                                            ? 'Add another shipment'
                                            : 'Create Shipment'}
                                    </Button>
                                </Box>
                            )}
                        </Box>
                    </Box>
                ) : (
                    <Box />
                )}
            </Box>
        </Popover>
    );
};

export default WarehousePopover;
