import {
    Box,
    Button,
    Divider,
    TextField,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material/';
import React, { ReactElement } from 'react';
import { MdCheck, MdChevronRight } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import SearchInput from '../../../../../../components/inputs/FieldInputs/SearchInput';
import AppNav from '../../../../../../components/Layout/AppNav/components';
import NavContent from '../../../../../../components/Layout/AppNav/components/NavContent';
import NavHeader from '../../../../../../components/Layout/NavHeader';
import { FulfillmentLineLotInput } from '../../../../../../graphql/FulfillmentLine/inputs/FulfillmentLineLotInput';
import { TinyLot } from '../../../../../../graphql/Lot/Lot';
import { useTinyLots } from '../../../../../../graphql/Lot/useLots';
import {
    OrderQuery,
    useOrder,
} from '../../../../../../graphql/Order/operations/useOrder';
import {
    CreateShipmentArgs,
    CreateShipmentRes,
    useShipmentCreation,
} from '../../../../../../graphql/Shipment/operations/useShipmentCreation';
import {
    UpdateShipmentArgs,
    UpdateShipmentRes,
    useShipmentUpdate,
} from '../../../../../../graphql/Shipment/operations/useShipmentUpdate';
import { niceList } from '../../../../../../utils/niceList';
import ShipmentFormPaper from './components/ShipmentFormPaper';
import LotDecision from './components/LotDecision';
import { Animation, AnimationType } from '../../../../../../media/Animation';
import {
    CodeType,
    useCode,
    useLazyCode,
} from '../../../../../../graphql/Code/useCode';
import CodeField from '../../../../../../components/inputs/FieldInputs/CodeField';
import {
    ScanLot,
    useScanLots,
} from '../../../../../../graphql/Scan/useScanLots';
import { useSnackbar } from '../../../../../../providers/SnackbarProvider';
import { useScanner } from '../../../../../../hooks/useScanner';
import { ScanType } from '../../../../../../graphql/Scan/ScanType';
import { DesktopDatePicker, LoadingButton } from '@mui/lab';
import CarefullButton from '../../../../../../components/inputs/Buttons/CarefulButton';
import { OperationResult } from '../../../../../../utils/types/OperationResult';
import Message from '../../../../../../components/feedback/Message';
import { Order } from '../../../../../../graphql/Order/Order';
import { FulfillmentEvents } from '../../../../../../graphql/FulfillmentEvent/useFulfillmentEvents';
import { ShipmentDocumentQuery } from '../../../../../../graphql/Shipment/operations/useShipmentDocument';
import { TinyOrdersQuery } from '../../../../../../graphql/Order/operations/useOrders';

const ShipmentForm = (): ReactElement => {
    const { order: order_id, bol: bol_id, id } = useParams();
    const { setSnackbar } = useSnackbar();
    const nav = useNavigate();

    const { palette, shape } = useTheme();

    const [state, setState] = React.useState<
        CreateShipmentArgs | UpdateShipmentArgs
    >({
        data: { lines: [], seal: '', date_created: new Date(), bol: '' },
        bol: bol_id || '',
    });

    const [result, setResult] = React.useState<null | OperationResult<
        UpdateShipmentRes | CreateShipmentRes
    >>(null);

    const [handleCreate, { loading: createLoading }] = useShipmentCreation({
        variables: 'id' in state ? undefined : state,
        onCompleted: (data) => setResult({ success: true, data }),
        onError: (error) => setResult({ success: false, error }),
        refetchQueries: [
            FulfillmentEvents,
            ShipmentDocumentQuery,
            OrderQuery,
            TinyOrdersQuery,
        ],
    });

    const [handleUpdate, { loading: updateLoading }] = useShipmentUpdate({
        variables: 'id' in state ? state : undefined,
        onCompleted: (data) => setResult({ success: true, data }),
        onError: (error) => setResult({ success: false, error }),
        refetchQueries: [
            FulfillmentEvents,
            ShipmentDocumentQuery,
            OrderQuery,
            TinyOrdersQuery,
        ],
    });

    const handleSubmit = () => {
        if ('id' in state) handleUpdate();
        else handleCreate();
    };

    const { data, error, loading } = useOrder({
        variables: {
            id: order_id || '',
        },
        onCompleted: ({ order }) => {
            const bols = order.itineraries.map((itin) => itin.bols).flat();
            const bol = bols.find((b) => b._id == bol_id);
            if (!bol) throw new Error('Failed to index BOL inside Order.');
            if (id) {
                // updating previous shipment
                const shipment = bol.shipments.find((s) => s._id == id);
                if (!shipment)
                    throw new Error('Failed to index Shipment inside BOL.');

                const additionalContents = bol.contents.filter(
                    (c) =>
                        !shipment.lines
                            .map((l) => l.item._id)
                            .includes(c.item._id)
                );

                setState({
                    id: shipment._id,
                    data: {
                        bol: bol.code || '',
                        date_created: shipment.date_created,
                        seal: shipment.seal,
                        lines: [
                            ...shipment.lines.map((line) => ({
                                pallet_count: line.pallet_count,
                                lots: line.lots.map((lot) => ({
                                    company: lot.lot.company._id,
                                    quantity: lot.client_qty,
                                    unit: lot.client_unit._id,
                                    code: lot.lot.code,
                                })),
                                item: line.item._id,
                                form_response: {
                                    responses: line.form_response.responses.map(
                                        ({
                                            response,
                                            category,
                                            question,
                                            options,
                                            required,
                                            passable_options,
                                        }) => ({
                                            response,
                                            category,
                                            question,
                                            options,
                                            required,
                                            passable_options,
                                        })
                                    ),
                                },
                            })),
                            ...additionalContents.map((con) => ({
                                lots: [],
                                item: con.item._id,
                                form_response: { responses: [] },
                                pallet_count: 0,
                            })),
                        ],
                    },
                });
            } else {
                setState({
                    bol: bol._id,
                    data: {
                        bol: bol.code || '',
                        date_created: new Date(),
                        seal: '',
                        lines: bol.contents.map((con) => ({
                            pallet_count: 0,
                            lots: [],
                            item: con.item._id,
                            form_response: { responses: [] },
                        })),
                    },
                });
            }
        },
    });

    const bol = data
        ? data.order.itineraries
              .map((itin) => itin.bols)
              .flat()
              .find((bol) => bol._id === bol_id) || null
        : null;

    const [search, setSearch] = React.useState('');
    const [decision, setDecision] = React.useState(false);

    const hanldeLotSelection = (
        lot: TinyLot | ScanLot,
        receipt_line?: string
    ) => {
        if (bol) {
            const lineIndex = state.data.lines.findIndex((line) => {
                return line.item == lot.item._id;
            });

            if (lineIndex !== -1) {
                const bolContent = bol.contents.find(
                    (c) => c.item._id == lot.item._id
                );
                const existingLine = state.data.lines[lineIndex].lots[0];
                const lotContent: FulfillmentLineLotInput = {
                    unit: bolContent
                        ? bolContent.client_unit._id
                        : existingLine
                        ? existingLine.unit
                        : '',
                    quantity: lot.qty,
                    code: lot.code,
                    company: lot.company._id,
                    receipt_line,
                };

                const copy = { ...state };
                copy.data.lines[lineIndex].lots.push(lotContent);
                setState(copy);
                setSearch('');
                setDecision(false);
            }
        }
    };

    const onLotLoad = (lots: TinyLot[]) => {
        if (lots.length == 0) {
            // setLotError(new Error('No lots found'));
        } else {
            setDecision(true);
        }
    };

    const {
        data: lotData,
        loading: lotLoading,
        error: lotError,
    } = useTinyLots({
        variables: {
            filter: {
                skip: 0,
                take: 10,
                item_ids: [...state.data.lines.map((l) => l.item)],
                code: search,
            },
        },
        skip: !search,
        onCompleted: ({ lots: { items } }) => {
            onLotLoad(items);
        },
        fetchPolicy: 'network-only',
    });

    const onLotSearch = (value: string) => {
        setSearch(value);
    };

    const [handleScan, { loading: scanLoading }] = useScanLots({
        onComplete: ({ lots, type, identifier }) => {
            if (lots.length == 1) {
                const match = state.data.lines
                    .map((l) => l.lots)
                    .flat()
                    .find(
                        (l) =>
                            l.code == lots[0].code &&
                            l.company == lots[0].company._id
                    );

                if (match) {
                    setSnackbar({
                        open: true,
                        variant: 'error',
                        message: 'Lot is already included',
                    });
                } else {
                    hanldeLotSelection(
                        lots[0],
                        type == ScanType.ReceiptLine ? identifier : undefined
                    );
                }
            } else {
                setSnackbar({
                    open: true,
                    variant: 'error',
                    message: 'Failed to find lot',
                });
            }
        },
        onError: (err) => {
            setSnackbar({
                open: true,
                variant: 'error',
                message: err.message,
            });
        },
    });

    useScanner({
        onScan: (code) => handleScan(code),
    });

    const getHoldup = () => {
        if (!state.data.bol) return 'Please enter a BOL Number';
        if (!state.data.seal) return 'Please enter a Seal Number';

        let res: string | null = null;

        for (const line of state.data.lines) {
            if (res == null) {
                if (!line.item) res = 'Each line needs an item';
                else if (line.lots.length == 0)
                    res = 'Each item line needs lot info';
                else if (!line.pallet_count)
                    res = 'Each item needs a pallet count';
                else {
                    for (const lot of line.lots) {
                        if (res == null) {
                            if (!lot.code) res = 'Each line needs a lot number';
                            else if (!lot.quantity)
                                res = 'Each line needs a qty';
                            else if (!lot.unit) res = 'Each line needs a unit';
                        }
                    }
                }
            }
        }

        return res;
    };

    const holdup = getHoldup();

    return (
        <AppNav loading={loading || scanLoading || lotLoading}>
            {result ? (
                result.success ? (
                    <Message
                        type="Success"
                        onComplete={() => {
                            const order: Order = Object.values(result.data)[0];

                            const shipments = order.itineraries
                                .map((itin) =>
                                    itin.bols.map((bol) => bol.shipments)
                                )
                                .flat()
                                .flat();

                            const shipmentIndex = shipments.findIndex(
                                (r) => r._id == id
                            );

                            if ('id' in state) {
                                if (shipmentIndex == -1) {
                                    // deleted
                                    nav('/warehouse/shipping');
                                } else {
                                    nav(`/warehouse/shipping/${id}`);
                                }
                            } else {
                                nav(
                                    `/warehouse/shipping/${
                                        shipments[shipments.length - 1]._id
                                    }/print`
                                );
                            }
                        }}
                    >
                        Shipment Saved!
                    </Message>
                ) : (
                    <Message
                        type="Error"
                        action={
                            <Button onClick={() => setResult(null)}>
                                Try again
                            </Button>
                        }
                    >
                        {result.error.message}
                    </Message>
                )
            ) : (
                <NavContent padding={{ header: 3, content: 3, footer: 0 }}>
                    {{
                        header: (
                            <NavHeader>
                                <Box sx={{}}>
                                    <Typography variant="crisp">{`${
                                        id ? 'Update' : 'New'
                                    } Shipment`}</Typography>
                                    {bol && (
                                        <Typography
                                            sx={{ paddingTop: 1 }}
                                            variant="h6"
                                        >
                                            {niceList(
                                                bol.contents.map(
                                                    (content) =>
                                                        content.item.name
                                                )
                                            )}
                                        </Typography>
                                    )}
                                </Box>
                                <Box />
                            </NavHeader>
                        ),
                        content: (
                            <Box sx={{ paddingBottom: 16 }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        gap: 3,
                                        maxWidth: 475,
                                        paddingBottom: 3,
                                    }}
                                >
                                    <DesktopDatePicker
                                        label="Date shipped"
                                        value={state.data.date_created}
                                        onChange={(date) => {
                                            if (date)
                                                setState({
                                                    ...state,
                                                    data: {
                                                        ...state.data,
                                                        date_created: date,
                                                    },
                                                });
                                        }}
                                        inputFormat="MM/dd/yyyy"
                                        renderInput={(params) => (
                                            <TextField fullWidth {...params} />
                                        )}
                                    />
                                    <CodeField
                                        label="BOL Number"
                                        autofill
                                        type={CodeType.Bol}
                                        value={state.data.bol}
                                        onChange={(bol) =>
                                            setState({
                                                ...state,
                                                data: { ...state.data, bol },
                                            })
                                        }
                                    />

                                    <TextField
                                        sx={{ minWidth: 125 }}
                                        label="Seal"
                                        value={state.data.seal}
                                        onChange={(e) =>
                                            setState({
                                                ...state,
                                                data: {
                                                    ...state.data,
                                                    seal: e.target.value || '',
                                                },
                                            })
                                        }
                                    />
                                </Box>
                                {bol && (
                                    <ShipmentFormPaper
                                        bol={bol}
                                        state={state.data}
                                        setState={(data) => {
                                            setState({ ...state, data });
                                        }}
                                    />
                                )}
                                <Box
                                    sx={{
                                        paddingTop: 3,
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                        gap: 3,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            background:
                                                palette.background.paper,
                                            p: 0,
                                            ...shape,
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Animation
                                            style={{ height: 56, padding: 0 }}
                                            type={
                                                palette.mode == 'dark'
                                                    ? 'barcodeDark'
                                                    : 'barcodeLight'
                                            }
                                        />
                                        <Typography
                                            sx={{ p: 2, paddingLeft: 1 }}
                                        >
                                            Scan pallets
                                        </Typography>
                                    </Box>
                                    <SearchInput
                                        adornment={null}
                                        loading={lotLoading}
                                        error={
                                            Boolean(search) &&
                                            !lotLoading &&
                                            lotData &&
                                            lotData.lots.items.length == 0
                                        }
                                        label={`Or search for lot`}
                                        helperText={
                                            lotError
                                                ? lotError.message
                                                : undefined
                                        }
                                        value={search}
                                        onChange={(val) =>
                                            onLotSearch(val || '')
                                        }
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 2,
                                        paddingTop: 4,
                                    }}
                                >
                                    <Tooltip arrow title={holdup || ''}>
                                        <Box>
                                            <LoadingButton
                                                loading={
                                                    updateLoading ||
                                                    createLoading
                                                }
                                                disabled={Boolean(holdup)}
                                                onClick={handleSubmit}
                                                size="large"
                                                endIcon={<MdCheck />}
                                                variant="contained"
                                                color="success"
                                            >
                                                Save Shipment
                                            </LoadingButton>
                                        </Box>
                                    </Tooltip>
                                </Box>
                            </Box>
                        ),
                    }}
                </NavContent>
            )}
            <LotDecision
                state_lots={state.data.lines.map((line) => line.lots).flat()}
                setSearch={(v) => setSearch(v)}
                search={search}
                loading={lotLoading}
                error={lotError}
                lots={lotData ? lotData.lots.items : []}
                decision={decision}
                onSelect={(lotVal) => {
                    if (lotVal) {
                        hanldeLotSelection(lotVal);
                    } else {
                        setSearch('');
                        setDecision(false);
                    }
                }}
            />
        </AppNav>
    );
};

export default ShipmentForm;
