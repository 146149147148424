import {
    Box,
    Divider,
    IconButton,
    List,
    ListItem,
    Typography,
    useTheme,
} from '@mui/material/';
import React, { ReactElement } from 'react';
import { MdClear } from 'react-icons/md';
import Spanish from '../../../../../../../../components/display/Spanish';
import { PackingSessionQuery } from '../../../../../../../../graphql/PackingSession/operations/usePackingSession';
import { usePackingSessionUpdate } from '../../../../../../../../graphql/PackingSession/operations/usePackingSessionUpdate';
import {
    PackingSession,
    PackingSessionLot,
} from '../../../../../../../../graphql/PackingSession/PackingSession';
import { getStateFromSession } from '../../../PalletizingLanding/components/SessionPreview';
import SessionContentScan from './components/SessionContentScan';

export interface SessionLotsProps {
    session: PackingSession;
}

const SessionLots = (props: SessionLotsProps): ReactElement => {
    const { session } = props;

    const { palette } = useTheme();

    const [handleUpdate, { loading: updateLoading }] =
        usePackingSessionUpdate();

    const dropLot = (id: string) => {
        const data = getStateFromSession(session);
        data.client_contents = data.client_contents.filter((c) => c.lot !== id);
        handleUpdate({
            variables: {
                id: session._id,
                data,
            },
            refetchQueries: [PackingSessionQuery],
        });
    };

    const getGroupedLots = (): Record<
        string,
        { item: PackingSessionLot['item']; lots: PackingSessionLot[] }
    > => {
        const res: Record<
            string,
            { item: PackingSessionLot['item']; lots: PackingSessionLot[] }
        > = {};

        for (const { lot } of session.client_contents) {
            if (res[lot.item._id]) {
                res[lot.item._id].lots.push(lot);
            } else {
                res[lot.item._id] = { item: lot.item, lots: [lot] };
            }
        }

        return res;
    };

    return (
        <Box
            sx={{
                p: 3,
                minWidth: 240,
                overflow: 'auto',
            }}
        >
            <Spanish
                sx={{ paddingLeft: 1 }}
                color="text.secondary"
                variant="h5"
            >
                Packaging Lots
            </Spanish>
            <Box p={1} />
            <Box>
                {Object.values(getGroupedLots()).map(({ item, lots }) => (
                    <Box key={'group_' + item._id} sx={{ paddingBottom: 2 }}>
                        <Box sx={{ p: 1 }}>
                            <Typography
                                color="text.secondary"
                                variant="subtitle1"
                            >
                                {item.name}
                            </Typography>
                        </Box>
                        <Divider />
                        <Box>
                            {lots.map((lot) => (
                                <Box
                                    sx={{
                                        p: 1,
                                        paddingTop: 0.5,
                                        paddingBottom: 0.5,
                                    }}
                                    key={'l_' + lot._id}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <Typography variant="h6">
                                            {lot.code}
                                        </Typography>
                                        <IconButton
                                            size="small"
                                            onClick={() => dropLot(lot._id)}
                                        >
                                            <MdClear />
                                        </IconButton>
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                ))}
            </Box>
            <SessionContentScan session={session} />
        </Box>
    );
};

export default SessionLots;
