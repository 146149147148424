import { gql } from '@apollo/client';
import { BaseFragment } from '../../Base/Base';
import { getMutationHook } from '../../types';
import {
    PackingSession,
    PackingSessionFragment,
    PackingSessionLotFragment,
} from '../PackingSession';
import { CreatePackingSessionInput } from '../inputs/CreatePackingSessionInput';

export const CreatePackingSessionMutation = gql`
    ${PackingSessionFragment}
    ${BaseFragment}
    ${PackingSessionLotFragment}
    mutation CreatePackingSessionMutation($data: CreatePackingSessionInput!) {
        createPackingSession(data: $data) {
            ...PackingSessionFragment
        }
    }
`;

export interface CreatePackingSessionRes {
    createPackingSession: PackingSession;
}

export interface CreatePackingSessionArgs {
    data: CreatePackingSessionInput;
}

export const usePackingSessionCreation = getMutationHook<
    CreatePackingSessionRes,
    CreatePackingSessionArgs
>(CreatePackingSessionMutation);
