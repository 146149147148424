import Box from '@mui/material/Box';
import React, { ReactElement } from 'react';
import { LotNode } from '../../../../../../../../graphql/LotGraph/LotNode/LotNode';
import ReactFlow, { Handle, NodeProps, Position } from 'react-flow-renderer';
import { Collapse, IconButton, Typography, useTheme } from '@mui/material/';
import Anima from '../../../../../../../../components/Layout/Anima';
import { MdExpandMore } from 'react-icons/md';
import { format } from 'date-fns';
import { dateFormats } from '../../../../../../../../utils/dateFormats';
import { Batch } from '../../../../../../../../graphql/Batch/Batch';
import { useBatch } from '../../../../../../../../graphql/Batch/operations/useBatch';

export interface BatchNodeRenderProps extends NodeProps {
    data: LotNode;
}

const BatchNodeRender = (props: BatchNodeRenderProps): ReactElement => {
    const {
        data: { item, company, code, _id, helper },
    } = props;

    const { palette, shape } = useTheme();

    const [showDetail, setShowDetail] = React.useState(false);

    const [batch, setBatch] = React.useState<Batch | null>(null);

    useBatch({
        skip: !showDetail,
        onCompleted: ({ batch }) => setBatch(batch),
        variables: { id: helper },
    });

    return (
        <Box sx={{ p: 2, ...shape, background: palette.background.paper }}>
            <Handle type="target" position={Position.Top} />
            <Handle type="source" position={Position.Bottom} />
            <Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography>Batch</Typography>
                    <Anima type="rotate" in={showDetail}>
                        <IconButton
                            size="small"
                            onClick={() => setShowDetail(!showDetail)}
                        >
                            <MdExpandMore />
                        </IconButton>
                    </Anima>
                </Box>
                <Collapse unmountOnExit in={showDetail && batch !== null}>
                    {batch && (
                        <Box>
                            <Typography color="text.secondary" variant="body2">
                                {`${batch.location.label} - ${batch.production_line.name}`}
                            </Typography>
                            <Typography color="text.secondary" variant="body2">
                                {`${batch.item.name}`}
                            </Typography>
                            <Typography
                                color="text.secondary"
                                variant="body2"
                            >{`Mixed by ${batch.created_by.name}`}</Typography>
                            <Typography color="text.secondary" variant="body2">
                                {format(
                                    new Date(batch.date_created),
                                    dateFormats.condensedDate
                                )}
                            </Typography>
                        </Box>
                    )}
                </Collapse>
            </Box>
        </Box>
    );
};

export default BatchNodeRender;
