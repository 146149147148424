import {
    Box,
    Button,
    ButtonGroup,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
    useTheme,
} from '@mui/material';
import React, { ReactElement } from 'react';
import { MdCalendarToday, MdTableRows } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import OurLocationField from '../../../../components/inputs/FieldInputs/OurLocationField';
import AppNav from '../../../../components/Layout/AppNav/components';
import NavContent from '../../../../components/Layout/AppNav/components/NavContent';
import NavHeader from '../../../../components/Layout/NavHeader';
import { useOurCompany } from '../../../../graphql/Company/operations/useOurCompany';
import ReceiptPanel from './components/ReceiptPanel';
import ShipmentPanel from './components/ShipmentPanel';
import WarehouseCalendar from './components/WarehouseCalendar';
import WarehouseTable from './components/WarehouseTable';

export interface WarehouseDashboardProps {
    view: 'Shipping' | 'Receiving';
}

export type WarehouseMode = 'Calendar' | 'Table';

const WarehouseDashboard = (props: WarehouseDashboardProps): ReactElement => {
    const { view } = props;

    const { receipt, shipment } = useParams();

    const { palette } = useTheme();

    const { data: companyData } = useOurCompany();

    const plantFromStorage = localStorage.getItem('WAREHOUSE_TABLEPLANT');

    const [plant, setPlant] = React.useState(
        plantFromStorage ? plantFromStorage : ''
    );

    React.useEffect(() => {
        if (plant) localStorage.setItem('WAREHOUSE_TABLEPLANT', plant);
    }, [plant]);

    const plants = React.useMemo(
        () => (companyData ? companyData.ourCompany.locations : []),
        [companyData]
    );

    React.useEffect(() => {
        if (plant == '' && plants.length > 0) setPlant(plants[0]._id);
    }, [plants, plant]);

    const fromStorage = localStorage.getItem('WAREHOUSE_MODE');

    const [mode, setMode] = React.useState<WarehouseMode>(
        fromStorage == 'Table' ? 'Table' : 'Calendar'
    );

    React.useEffect(() => {
        localStorage.setItem('WAREHOUSE_MODE', mode);
    }, [mode]);

    const [loading, setLoading] = React.useState(false);

    return (
        <AppNav loading={loading}>
            <NavContent padding={{ header: 2, content: 2, footer: 0 }}>
                {{
                    header: (
                        <NavHeader>
                            <Typography variant="crisp">{view}</Typography>
                            <Box sx={{ display: 'flex', gap: 2 }}>
                                <ToggleButtonGroup value={plant}>
                                    {plants.map((p, plantIndex) => (
                                        <ToggleButton
                                            onClick={() => {
                                                setPlant(p._id);
                                            }}
                                            key={'plant_' + p._id}
                                            value={p._id}
                                            sx={{ textTransform: 'none' }}
                                        >
                                            {p.label}
                                        </ToggleButton>
                                    ))}
                                </ToggleButtonGroup>
                                <ToggleButtonGroup value={mode}>
                                    <ToggleButton
                                        value={'Calendar'}
                                        onClick={() => setMode('Calendar')}
                                        sx={{ textTransform: 'none' }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 1,
                                            }}
                                        >
                                            <MdCalendarToday
                                                style={{ fontSize: '1rem' }}
                                            />
                                            Calendar
                                        </Box>
                                    </ToggleButton>
                                    <ToggleButton
                                        value={'Table'}
                                        onClick={() => setMode('Table')}
                                        sx={{ textTransform: 'none' }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 1,
                                            }}
                                        >
                                            <MdTableRows
                                                style={{ fontSize: '1rem' }}
                                            />
                                            Table
                                        </Box>
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Box>
                        </NavHeader>
                    ),
                    content:
                        mode == 'Calendar' ? (
                            <Box
                                sx={{
                                    height: '100%',
                                    overflow: 'hidden',
                                    display: 'flex',
                                    flexFlow: 'column',
                                }}
                            >
                                <WarehouseCalendar plant={plant} view={view}>
                                    {(loading) => setLoading(loading)}
                                </WarehouseCalendar>
                                <Box p={1} />
                            </Box>
                        ) : (
                            <WarehouseTable plant={plant} view={view} />
                        ),
                }}
            </NavContent>
            <ReceiptPanel receipt={receipt || null} />
            <ShipmentPanel shipment={shipment || null} />
        </AppNav>
    );
};

export default WarehouseDashboard;
