import {
    Box,
    Button,
    Collapse,
    FormControlLabel,
    Grow,
    MenuItem,
    Switch,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import React, { ReactElement } from 'react';
import { MdCheck } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { UserRole } from '../../../../../../auth/UserRole';
import Message from '../../../../../../components/feedback/Message';
import SuccessButton from '../../../../../../components/inputs/Buttons/SuccessButton';
import RoleInput from '../../../../../../components/inputs/FieldInputs/RoleInput';
import FormRow from '../../../../../../components/Layout/FormRow';
import ResponsiveDialog from '../../../../../../components/Layout/ResponsiveDialog';
import {
    CreateProfileArgs,
    CreateProfileRes,
    useProfileCreation,
} from '../../../../../../graphql/People/useProfileCreation';
import { ProfilesQuery } from '../../../../../../graphql/People/useProfiles';
import {
    UpdateProfileArgs,
    UpdateProfileRes,
    useProfileUpdate,
} from '../../../../../../graphql/People/useProfileUpdate';
import { useProfile } from '../../../../../../graphql/Profile/useProfile';
import { OperationResult } from '../../../../../../utils/types/OperationResult';
import {
    checkPassword,
    PasswordPolicy,
} from '../../../../../MyAccount/checkPassword';

export interface ProfileDialogProps {
    focus: string | true | null;
}

const ProfileDialog = (props: ProfileDialogProps): ReactElement => {
    const { focus } = props;
    const nav = useNavigate();
    const { palette } = useTheme();

    const [result, setResult] = React.useState<null | OperationResult<
        CreateProfileRes | UpdateProfileRes
    >>(null);

    const [state, setState] = React.useState<
        | ({
              type: 'create';
              confirm_password: string;
          } & CreateProfileArgs['data'])
        | ({
              type: 'update';
              confirm_password: string;
          } & UpdateProfileArgs['data'])
    >({
        type: 'create',
        given_name: '',
        family_name: '',
        temporary_password: '',
        email: '',
        role: UserRole.User,
        confirm_password: '',
    });

    const [ready, validation] = checkPassword(
        state.type == 'create'
            ? state.temporary_password
            : state.password || '',
        state.confirm_password
    );

    const { data, error, loading } = useProfile({
        variables: focus !== null && focus !== true ? { id: focus } : undefined,
        skip: focus == null || focus == true,
        fetchPolicy: 'network-only',
        onCompleted: ({ profile }) => {
            if (profile.username)
                setState({
                    type: 'update',
                    given_name: profile.given_name || '',
                    family_name: profile.family_name || '',
                    role: profile.roles[0],
                    username: profile.username,
                    blocked: profile.blocked,
                    confirm_password: '',
                });
            else
                setState({
                    type: 'update',
                    given_name: profile.given_name || '',
                    family_name: profile.family_name || '',
                    role: profile.roles[0],
                    email: profile.email,
                    blocked: profile.blocked,
                    confirm_password: '',
                });
        },
    });

    const profile = data ? data.profile : null;

    const [handleCreate, { loading: createLoading }] = useProfileCreation({
        onCompleted: (data) => setResult({ success: true, data }),
        onError: (error) => setResult({ success: false, error }),
        refetchQueries: [ProfilesQuery],
        variables:
            state.type == 'create'
                ? 'email' in state
                    ? {
                          data: {
                              given_name: state.given_name,
                              family_name: state.family_name,
                              email: state.email,
                              temporary_password: state.temporary_password,
                              role: state.role,
                          },
                      }
                    : {
                          data: {
                              given_name: state.given_name,
                              family_name: state.family_name,
                              username: state.username,
                              temporary_password: state.temporary_password,
                              role: state.role,
                          },
                      }
                : undefined,
    });

    const getUpdateVariables = (): UpdateProfileArgs | undefined => {
        if (state.type == 'create' || focus == null || focus == true)
            return undefined;
        if ('email' in state) {
            const { confirm_password, type, ...rest } = state;
            return {
                id: focus,
                data: rest,
            };
        } else {
            const { confirm_password, type, ...rest } = state;
            return {
                id: focus,
                data: rest,
            };
        }
    };

    const [handleUpdate, { loading: updateLoading }] = useProfileUpdate({
        onCompleted: (data) => setResult({ success: true, data }),
        onError: (error) => setResult({ success: false, error }),
        variables: getUpdateVariables(),
        refetchQueries: [ProfilesQuery],
    });

    const handleClose = () => {
        nav('/people/profiles');
        setState({
            type: 'create',
            given_name: '',
            family_name: '',
            temporary_password: '',
            email: '',
            role: UserRole.User,
            confirm_password: '',
        });
        setResult(null);
    };

    return (
        <ResponsiveDialog
            open={focus !== null}
            title={profile ? profile.name : 'New Profile'}
            onClose={handleClose}
        >
            {result && result.success == false ? (
                <Box>
                    <Message
                        type="Error"
                        action={
                            <Button onClick={() => setResult(null)}>
                                Try again
                            </Button>
                        }
                    >
                        {result.error.message}
                    </Message>
                </Box>
            ) : (
                <Box>
                    <FormRow>
                        <TextField
                            label="Given Name"
                            fullWidth
                            value={state.given_name}
                            onChange={(e) =>
                                setState({
                                    ...state,
                                    given_name: e.target.value || '',
                                })
                            }
                        />
                        <TextField
                            label="Family Name"
                            fullWidth
                            value={state.family_name}
                            onChange={(e) =>
                                setState({
                                    ...state,
                                    family_name: e.target.value || '',
                                })
                            }
                        />
                    </FormRow>
                    <FormRow>
                        <RoleInput
                            label="User Role"
                            fullWidth
                            value={state.role}
                            onChange={(role) =>
                                setState({
                                    ...state,
                                    role,
                                })
                            }
                        />
                        {state.type == 'update' && (
                            <TextField
                                InputProps={{
                                    style: {
                                        color: state.blocked
                                            ? palette.warning.main
                                            : palette.success.main,
                                    },
                                }}
                                value={state.blocked ? 'Blocked' : 'Active'}
                                label="Account Status"
                                select
                                fullWidth
                                onChange={(e) => {
                                    if (e.target.value == 'Blocked') {
                                        setState({ ...state, blocked: true });
                                    } else {
                                        setState({ ...state, blocked: false });
                                    }
                                }}
                            >
                                <MenuItem value={'Active'}>Active</MenuItem>
                                <MenuItem value={'Blocked'}>Blocked</MenuItem>
                            </TextField>
                        )}
                    </FormRow>
                    <FormRow>
                        <TextField
                            select
                            label="Login Method"
                            fullWidth
                            value={
                                'username' in state &&
                                state.username !== undefined
                                    ? 'Username'
                                    : 'Email'
                            }
                            onChange={(e) => {
                                if (e.target.value == 'Username') {
                                    setState({
                                        ...state,
                                        email: undefined,
                                        username: '',
                                    });
                                } else {
                                    setState({
                                        ...state,
                                        email: '',
                                        username: undefined,
                                    });
                                }
                            }}
                        >
                            <MenuItem value={'Username'}>Username</MenuItem>
                            <MenuItem value={'Email'}>Email</MenuItem>
                        </TextField>
                        <TextField
                            label={
                                'username' in state &&
                                state.username !== undefined
                                    ? 'Username'
                                    : 'Email'
                            }
                            fullWidth
                            value={
                                'username' in state &&
                                state.username !== undefined
                                    ? state.username
                                    : 'email' in state
                                    ? state.email
                                    : ''
                            }
                            onChange={(e) => {
                                if (
                                    'username' in state &&
                                    state.username !== undefined
                                ) {
                                    setState({
                                        ...state,
                                        username: e.target.value,
                                    });
                                } else if ('email' in state) {
                                    setState({
                                        ...state,
                                        email: e.target.value,
                                    });
                                }
                            }}
                        />
                    </FormRow>

                    {state.type == 'update' && (
                        <FormRow>
                            <FormControlLabel
                                control={
                                    <Switch
                                        value={
                                            state.password !== undefined
                                                ? true
                                                : false
                                        }
                                        onChange={(e, checked) => {
                                            setState({
                                                ...state,
                                                password: checked
                                                    ? ''
                                                    : undefined,
                                            });
                                        }}
                                    />
                                }
                                label="Reset Password"
                            />
                        </FormRow>
                    )}
                    <Collapse
                        in={
                            (state.type == 'create' && focus == true) ||
                            (state.type == 'update' &&
                                state.password !== undefined)
                        }
                    >
                        <Box>
                            <FormRow>
                                <TextField
                                    type="password"
                                    label={
                                        state.type == 'update'
                                            ? 'New password'
                                            : 'Password'
                                    }
                                    value={
                                        state.type == 'create'
                                            ? state.temporary_password
                                            : state.password
                                    }
                                    onChange={(e) => {
                                        if (state.type == 'create') {
                                            setState({
                                                ...state,
                                                temporary_password:
                                                    e.target.value || '',
                                            });
                                        } else {
                                            setState({
                                                ...state,
                                                password: e.target.value || '',
                                            });
                                        }
                                    }}
                                    fullWidth
                                />
                            </FormRow>
                            <FormRow>
                                <TextField
                                    type="password"
                                    label={'Confirm Pasword'}
                                    value={state.confirm_password}
                                    onChange={(e) => {
                                        setState({
                                            ...state,
                                            confirm_password:
                                                e.target.value || '',
                                        });
                                    }}
                                    fullWidth
                                />
                            </FormRow>
                            <FormRow>
                                <Box>
                                    {Object.keys(validation).map(
                                        (key, policyIndex) => (
                                            <Box
                                                key={`policy_${policyIndex}`}
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: 1,
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        paddingBottom: 0.5,
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            height: 8,
                                                            width: 8,
                                                            borderRadius: 16,
                                                            background:
                                                                validation[
                                                                    key as PasswordPolicy
                                                                ]
                                                                    ? palette
                                                                          .success
                                                                          .main
                                                                    : palette
                                                                          .error
                                                                          .main,
                                                        }}
                                                    />
                                                </Box>
                                                <Typography variant="body2">
                                                    {key}
                                                </Typography>
                                            </Box>
                                        )
                                    )}
                                </Box>
                            </FormRow>
                        </Box>
                    </Collapse>
                    <Box>
                        <SuccessButton
                            onClick={() => {
                                if (state.type == 'create') handleCreate();
                                else handleUpdate();
                            }}
                            loading={createLoading || updateLoading}
                            success={
                                result && result.success
                                    ? 'createProfile' in result.data
                                        ? 'Profile created!'
                                        : 'Profile updated!'
                                    : null
                            }
                            onSuccess={handleClose}
                            endIcon={!result ? <MdCheck /> : undefined}
                        >
                            Save Profile
                        </SuccessButton>
                    </Box>
                </Box>
            )}
        </ResponsiveDialog>
    );
};

export default ProfileDialog;
