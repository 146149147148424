import { LoadingButton } from '@mui/lab';
import {
    Box,
    Button,
    Chip,
    ClickAwayListener,
    LinearProgress,
    TextField,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material/';
import React, { ReactElement } from 'react';
import { MdCheck } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import Spanish from '../../../../../../../../../../components/display/Spanish';
import Message from '../../../../../../../../../../components/feedback/Message';
import { Batch } from '../../../../../../../../../../graphql/Batch/Batch';
import {
    UpdateBatchRes,
    useBatchUpdate,
} from '../../../../../../../../../../graphql/Batch/operations/useBatchUpdate';
import { BatchLine } from '../../../../../../../../../../graphql/BatchLine/BatchLine';
import { RecipeSection } from '../../../../../../../../../../graphql/RecipeSection/RecipeSection';
import { RecipeStep } from '../../../../../../../../../../graphql/RecipeStep/RecipeStep';
import { RecipeVersion } from '../../../../../../../../../../graphql/RecipeVersion/RecipeVersion';
import { fraction } from '../../../../../../../../../../utils/fraction';
import { niceList } from '../../../../../../../../../../utils/niceList';
import { OperationResult } from '../../../../../../../../../../utils/types/OperationResult';
import { getStateFromBatch } from '../../../../../BatchEntry';
import BatchContentScan from './components/BatchContentScan';
import { isSectionComplete, useGate } from './useGate';

export interface BatchContentProps {
    batch: Batch;
    recipe: RecipeVersion;
}

export type BatchFocus =
    | { type: 'step'; step: RecipeStep }
    | { type: 'section'; section: RecipeSection; index: number }
    | { type: 'crumb' }
    | null;

export const getGroupedCrumbs = (
    batch: Batch
): Record<
    string,
    { item: { _id: string; name: string }; lines: BatchLine[] }
> => {
    const res: Record<
        string,
        { item: { _id: string; name: string }; lines: BatchLine[] }
    > = {};

    const filtered = batch.lines.filter((l) => !l.recipe_step);

    for (const line of filtered) {
        if (line.content) {
            if (!res[line.content.lot.item._id]) {
                res[line.content.lot.item._id] = {
                    item: line.content.lot.item,
                    lines: [line],
                };
            } else {
                res[line.content.lot.item._id].lines.push(line);
            }
        }
    }

    return res;
};

const BatchContent = (props: BatchContentProps): ReactElement => {
    const { batch, recipe } = props;

    const { shape, palette } = useTheme();

    const nav = useNavigate();

    const [focus, setFocus] = React.useState<BatchFocus>(null);

    const [result, setResult] =
        React.useState<null | OperationResult<UpdateBatchRes>>(null);

    const gate = useGate(batch, recipe);

    const [updateBatch, { loading: batchLoading }] = useBatchUpdate();

    const loading = batchLoading;

    const getHoldup = (): string | null => {
        let holdup = null;

        for (const step of recipe.sections.map((s) => s.steps).flat()) {
            if (step.content) {
                const matches = batch.lines.filter(
                    (line) => line.recipe_step == step._id
                );

                if (matches.length == 0 && holdup == null) {
                    holdup = 'All ingredient steps need lots';
                }
            }
        }
        return holdup;
    };

    const holdup = getHoldup();

    return result ? (
        result.success ? (
            <Message
                type="Success"
                onComplete={() => nav('/production/mixing')}
            >
                Batch saved!
            </Message>
        ) : (
            <Message
                type="Error"
                action={
                    <Button onClick={() => setResult(null)}>Try again</Button>
                }
            >
                {result.error.message}
            </Message>
        )
    ) : (
        <React.Fragment>
            <ClickAwayListener onClickAway={() => setFocus(null)}>
                <Box
                    sx={{
                        ...shape,
                        flex: 1,
                        maxWidth: 725,
                        overflow: 'hidden',
                        marginLeft: 2,
                        marginRight: 2,
                    }}
                >
                    {loading && (
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                zIndex: 11,
                            }}
                        >
                            <LinearProgress />
                        </Box>
                    )}
                    <Box sx={{ p: 1.5, background: palette.background.paper }}>
                        <Typography variant="h6">
                            {batch.recipe.name}
                        </Typography>
                        <Spanish variant="subtitle1">
                            {[batch.item.spanish_name, batch.item.name]}
                        </Spanish>
                    </Box>
                    <Box>
                        {recipe.sections.map((section, sectionIndex) => (
                            <Box
                                sx={{ background: palette.tonal }}
                                key={'s_' + sectionIndex}
                            >
                                <Box
                                    sx={{
                                        p: 1.5,
                                        gap: 1,
                                        background: palette.background.paper,
                                        display: 'flex',
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                        border:
                                            focus &&
                                            focus.type == 'section' &&
                                            focus.index == sectionIndex
                                                ? `2px solid ${palette.primary.main}`
                                                : undefined,
                                    }}
                                    onClick={() => {
                                        setFocus({
                                            type: 'section',
                                            section,
                                            index: sectionIndex,
                                        });
                                    }}
                                >
                                    <Spanish
                                        variant="caption"
                                        color="text.secondary"
                                    >
                                        {section.label ||
                                            `Section ${sectionIndex + 1}`}
                                    </Spanish>
                                    <Box>
                                        {isSectionComplete(
                                            sectionIndex,
                                            gate,
                                            batch,
                                            recipe
                                        ) && (
                                            <MdCheck
                                                style={{
                                                    color: palette.success.main,
                                                }}
                                            />
                                        )}
                                    </Box>
                                </Box>
                                <Box>
                                    {section.steps.map((step, stepIndex) => {
                                        const stepLots = batch.lines.filter(
                                            (l) => l.recipe_step == step._id
                                        );

                                        return (
                                            <Box
                                                onClick={() =>
                                                    setFocus({
                                                        type: 'step',
                                                        step,
                                                    })
                                                }
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent:
                                                        'space-between',
                                                    p: 1.5,
                                                    cursor: 'pointer',
                                                    border:
                                                        focus &&
                                                        focus.type == 'step' &&
                                                        focus.step._id ==
                                                            step._id
                                                            ? `2px solid ${palette.primary.main}`
                                                            : undefined,
                                                }}
                                                key={`s_${sectionIndex}_step_${stepIndex}`}
                                            >
                                                <Box>
                                                    {step.content ? (
                                                        <Box
                                                            sx={{
                                                                display: 'Flex',
                                                                gap: 3,
                                                            }}
                                                        >
                                                            <Spanish variant="body2">
                                                                {[
                                                                    niceList(
                                                                        step.content.items.map(
                                                                            (
                                                                                i
                                                                            ) =>
                                                                                i.spanish_name
                                                                        ),
                                                                        'or'
                                                                    ),
                                                                    niceList(
                                                                        step.content.items.map(
                                                                            (
                                                                                i
                                                                            ) =>
                                                                                i.name
                                                                        ),
                                                                        'or'
                                                                    ),
                                                                ]}
                                                            </Spanish>
                                                            <Spanish
                                                                color="text.secondary"
                                                                variant="body2"
                                                            >
                                                                {[
                                                                    `${fraction(
                                                                        step
                                                                            .content
                                                                            .client_qty
                                                                    )} ${
                                                                        step
                                                                            .content
                                                                            .client_unit[
                                                                            step
                                                                                .content
                                                                                .client_qty >
                                                                                0 &&
                                                                            step
                                                                                .content
                                                                                .client_qty <=
                                                                                1
                                                                                ? 'spanish_name'
                                                                                : 'spanish_plural'
                                                                        ]
                                                                    }`,
                                                                    `${fraction(
                                                                        step
                                                                            .content
                                                                            .client_qty
                                                                    )} ${
                                                                        step
                                                                            .content
                                                                            .client_unit[
                                                                            step
                                                                                .content
                                                                                .client_qty >
                                                                                0 &&
                                                                            step
                                                                                .content
                                                                                .client_qty <=
                                                                                1
                                                                                ? 'name'
                                                                                : 'plural'
                                                                        ]
                                                                    }`,
                                                                ]}
                                                            </Spanish>
                                                        </Box>
                                                    ) : (
                                                        <Spanish variant="body2">
                                                            {[
                                                                step.spanish ||
                                                                    '',
                                                                step.english ||
                                                                    '',
                                                            ]}
                                                        </Spanish>
                                                    )}
                                                </Box>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        gap: 1,
                                                    }}
                                                >
                                                    {stepLots.map(
                                                        (line, lineIndex) => (
                                                            <Chip
                                                                color="success"
                                                                key={
                                                                    'step_' +
                                                                    stepIndex +
                                                                    '_line_' +
                                                                    lineIndex
                                                                }
                                                                label={
                                                                    step.content &&
                                                                    step.content
                                                                        .items
                                                                        .length ==
                                                                        1 ? (
                                                                        line
                                                                            .content
                                                                            .lot
                                                                            .code
                                                                    ) : (
                                                                        <Box
                                                                            sx={{
                                                                                display:
                                                                                    'flex',
                                                                                flexFlow:
                                                                                    'column',
                                                                            }}
                                                                        >
                                                                            <Typography
                                                                                sx={{
                                                                                    lineHeight: 1,
                                                                                }}
                                                                                variant="caption"
                                                                            >
                                                                                {
                                                                                    line
                                                                                        .content
                                                                                        .lot
                                                                                        .code
                                                                                }
                                                                            </Typography>
                                                                            <Typography
                                                                                sx={{
                                                                                    lineHeight: 1,
                                                                                }}
                                                                                variant="caption"
                                                                            >
                                                                                {
                                                                                    line
                                                                                        .content
                                                                                        .lot
                                                                                        .item
                                                                                        .name
                                                                                }
                                                                            </Typography>
                                                                        </Box>
                                                                    )
                                                                }
                                                                onDelete={() => {
                                                                    const data =
                                                                        getStateFromBatch(
                                                                            batch
                                                                        );

                                                                    data.lines =
                                                                        data.lines.filter(
                                                                            (
                                                                                l
                                                                            ) =>
                                                                                !(
                                                                                    l.recipe_step ===
                                                                                        step._id &&
                                                                                    l
                                                                                        .content
                                                                                        .lot ===
                                                                                        line
                                                                                            .content
                                                                                            .lot
                                                                                            ._id
                                                                                )
                                                                        );

                                                                    updateBatch(
                                                                        {
                                                                            variables:
                                                                                {
                                                                                    id: batch._id,
                                                                                    data,
                                                                                },
                                                                        }
                                                                    );
                                                                }}
                                                            />
                                                        )
                                                    )}
                                                </Box>
                                            </Box>
                                        );
                                    })}
                                    <Box p={1} />
                                </Box>
                            </Box>
                        ))}
                        <Box
                            sx={{
                                p: 1.5,
                                background: palette.background.paper,
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                cursor: 'pointer',
                                border:
                                    focus && focus.type == 'crumb'
                                        ? `2px solid ${palette.primary.main}`
                                        : undefined,
                            }}
                            onClick={() => setFocus({ type: 'crumb' })}
                        >
                            <Spanish variant="caption" color="text.secondary">
                                {`Crumbs, toppings, and additional lots`}
                            </Spanish>
                            <MdCheck
                                style={{
                                    color: palette.success.main,
                                }}
                            />
                        </Box>
                        <Box>
                            {Object.values(getGroupedCrumbs(batch)).map(
                                ({ item, lines }, crumbIndex) => (
                                    <Box
                                        key={'c_' + crumbIndex}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            background: palette.tonal,
                                            p: 1.5,
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <Box>
                                            <Spanish variant="body2">
                                                {[item.name, item.name]}
                                            </Spanish>
                                        </Box>
                                        <Box sx={{ display: 'flex', gap: 1 }}>
                                            {lines.map((line, lineIndex) => (
                                                <Chip
                                                    color="success"
                                                    key={
                                                        'crumb_' +
                                                        crumbIndex +
                                                        '_line_' +
                                                        lineIndex
                                                    }
                                                    label={
                                                        line.content.lot.code
                                                    }
                                                    onDelete={() => {
                                                        const data =
                                                            getStateFromBatch(
                                                                batch
                                                            );

                                                        data.lines =
                                                            data.lines.filter(
                                                                (l) =>
                                                                    !(
                                                                        l.recipe_step ===
                                                                            null &&
                                                                        l
                                                                            .content
                                                                            .lot ===
                                                                            line
                                                                                .content
                                                                                .lot
                                                                                ._id
                                                                    )
                                                            );

                                                        updateBatch({
                                                            variables: {
                                                                id: batch._id,
                                                                data,
                                                            },
                                                        });
                                                    }}
                                                />
                                            ))}
                                        </Box>
                                    </Box>
                                )
                            )}
                        </Box>
                    </Box>
                    <Box p={1} />
                    <Box>
                        {recipe.parameters.map((param, paramIndex) => (
                            <Typography
                                variant="body2"
                                color="text.secondary"
                                key={'param_' + paramIndex}
                            >
                                {param}
                            </Typography>
                        ))}
                    </Box>
                    <BatchContentScan {...{ batch, recipe, focus }} />
                </Box>
            </ClickAwayListener>
            <Box
                sx={{
                    position: 'absolute',
                    bottom: 0,
                    right: 0,
                    paddingBottom: 4,
                    paddingRight: 4,
                }}
            >
                <Tooltip arrow title={holdup || ''}>
                    <Box>
                        <LoadingButton
                            disabled={Boolean(holdup)}
                            color="success"
                            variant="contained"
                            size="large"
                            loading={batchLoading}
                            endIcon={<MdCheck />}
                            onClick={() => {
                                updateBatch({
                                    variables: {
                                        id: batch._id,
                                        data: {
                                            ...getStateFromBatch(batch),
                                            date_completed:
                                                !batch.date_abandoned &&
                                                !batch.date_abandoned
                                                    ? new Date()
                                                    : undefined,
                                        },
                                    },
                                    onCompleted: (data) => {
                                        setResult({ success: true, data });
                                    },
                                    onError: (error) => {
                                        setResult({ success: false, error });
                                    },
                                });
                            }}
                        >
                            Complete Batch
                        </LoadingButton>
                    </Box>
                </Tooltip>
            </Box>
        </React.Fragment>
    );
};

export default BatchContent;
