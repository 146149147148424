import { LoadingButton } from '@mui/lab';
import {
    Box,
    Collapse,
    FormControlLabel,
    IconButton,
    RadioGroup,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
    useTheme,
    Checkbox,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Switch,
    Button,
    Tooltip,
} from '@mui/material';
import React, { ReactElement } from 'react';
import { BsBoxSeam } from 'react-icons/bs';
import {
    MdCheckBox,
    MdCheckBoxOutlineBlank,
    MdClear,
    MdRadioButtonChecked,
    MdRadioButtonUnchecked,
} from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import SearchInput from '../../../../../../../../components/inputs/FieldInputs/SearchInput';
import FormRow from '../../../../../../../../components/Layout/FormRow';
import { OurCompany } from '../../../../../../../../graphql/Company/operations/useOurCompany';
import { useTinyProducts } from '../../../../../../../../graphql/Item/extensions/Product/operations/useProducts';
import { TinyProduct } from '../../../../../../../../graphql/Item/extensions/Product/Product';
import { ProductFilter } from '../../../../../../../../graphql/Item/extensions/Product/ProductFilter';
import { CreatePackingSessionInput } from '../../../../../../../../graphql/PackingSession/inputs/CreatePackingSessionInput';
import { PackingSessionQuery } from '../../../../../../../../graphql/PackingSession/operations/usePackingSession';
import { usePackingSessionCreation } from '../../../../../../../../graphql/PackingSession/operations/usePackingSessionCreation';
import { PackingSessionsQuery } from '../../../../../../../../graphql/PackingSession/operations/usePackingSessions';
import {
    Animation,
    AnimationType,
} from '../../../../../../../../media/Animation';
import { useSnackbar } from '../../../../../../../../providers/SnackbarProvider';

const LaunchSession = (props: { company: OurCompany }): ReactElement => {
    const { company } = props;
    const { palette, shape } = useTheme();
    const { setSnackbar } = useSnackbar();
    const nav = useNavigate();

    const [state, setState] = React.useState<CreatePackingSessionInput>({
        date_completed: null,
        product: '',
        location: '',
        production_lines: [],
        use_trays: false,
        client_contents: [],
    });

    const [create, { loading }] = usePackingSessionCreation({
        variables: {
            data: state,
        },
        onCompleted: ({ createPackingSession }) =>
            nav(createPackingSession._id),
        onError: (error) =>
            setSnackbar({
                variant: 'error',
                message: 'There was a problem starting the packing session.',
                open: true,
            }),
        refetchQueries: [PackingSessionQuery, PackingSessionsQuery],
    });

    const [product, setProduct] = React.useState<TinyProduct | null>(null);

    React.useEffect(() => {
        if (product && !state.product) {
            setState({ ...state, product: product._id });
        } else if (!product && Boolean(state.product)) {
            setState({ ...state, product: '' });
        }
    }, [state, product]);

    const [filter, setFilter] = React.useState<ProductFilter>({
        skip: 0,
        take: 1,
        name: '',
    });

    useTinyProducts({
        variables: { filter },
        skip: !filter.name,
        onCompleted: ({ products }) => {
            if (products.count > 0) {
                setProduct(products.items[0]);
            }
        },
        fetchPolicy: 'network-only',
    });

    const selectedLocation =
        company.locations.find((l) => l._id == state.location) || null;

    const getHoldup = (): string | null => {
        if (!state.location) return 'Please select a location';
        if (!state.product) return 'Please select a product';
        return null;
    };

    const holdup = getHoldup();

    return (
        <Box
            sx={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box
                sx={{
                    flex: 1,
                    maxWidth: 400,
                    display: 'flex',
                    flexFlow: 'column',
                    gap: 2,
                }}
            >
                <Box
                    sx={{
                        ...shape,
                        background: palette.tonal,
                        overflow: 'hidden',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2,
                            background: palette.background.paper,
                        }}
                    >
                        <Box sx={{ display: 'flex' }}>
                            {product ? (
                                <Box sx={{ display: 'flex', p: 2 }}>
                                    <BsBoxSeam
                                        style={{
                                            fontSize: '3rem',
                                            color: palette.text.secondary,
                                        }}
                                    />
                                </Box>
                            ) : (
                                <Animation
                                    style={{ height: 120 }}
                                    type={
                                        palette.mode == 'dark'
                                            ? 'barcodeDark'
                                            : 'barcodeLight'
                                    }
                                />
                            )}
                        </Box>
                        {product ? (
                            <Box
                                sx={{
                                    flex: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    paddingRight: 2,
                                }}
                            >
                                <Box sx={{ flex: 1 }}>
                                    <Typography variant="h5">
                                        {product.name}
                                    </Typography>
                                </Box>
                                <IconButton
                                    onClick={() => {
                                        setProduct(null);
                                    }}
                                >
                                    <MdClear />
                                </IconButton>
                            </Box>
                        ) : (
                            <Box>
                                <Typography variant="h5">
                                    Scan a Master UPC
                                </Typography>
                            </Box>
                        )}
                    </Box>
                    <Collapse in={Boolean(state.product)}>
                        <Box sx={{ p: 2 }}>
                            <Typography>Where are you packing?</Typography>
                            <Box p={0.5} />
                            <FormRow>
                                <ToggleButtonGroup
                                    fullWidth
                                    value={state.location}
                                >
                                    {company.locations.map((plant) => (
                                        <ToggleButton
                                            sx={{ textTransform: 'none' }}
                                            key={'l_' + plant._id}
                                            value={plant._id}
                                            onClick={() =>
                                                setState({
                                                    ...state,
                                                    location: plant._id,
                                                    production_lines: [],
                                                })
                                            }
                                        >
                                            {plant.label}
                                        </ToggleButton>
                                    ))}
                                </ToggleButtonGroup>
                            </FormRow>
                            <Collapse in={selectedLocation !== null}>
                                <Box>
                                    <Box p={0.5} />
                                    <Typography>
                                        Which lines (if any) you are packing
                                        from
                                    </Typography>
                                    <Box p={0.5} />
                                    <ToggleButtonGroup
                                        fullWidth
                                        orientation="vertical"
                                        value={state.production_lines}
                                    >
                                        {(selectedLocation
                                            ? selectedLocation.production_lines
                                            : []
                                        ).map((line) => (
                                            <ToggleButton
                                                sx={{
                                                    justifyContent:
                                                        'flex-start',
                                                    textAlign: 'left',
                                                    textTransform: 'none',
                                                }}
                                                value={line._id}
                                                key={'l_' + line._id}
                                                onClick={() => {
                                                    if (
                                                        !state.production_lines.includes(
                                                            line._id
                                                        )
                                                    ) {
                                                        setState({
                                                            ...state,
                                                            production_lines: [
                                                                ...state.production_lines,
                                                                line._id,
                                                            ],
                                                        });
                                                    } else {
                                                        setState({
                                                            ...state,
                                                            production_lines:
                                                                state.production_lines.filter(
                                                                    (l) =>
                                                                        l !==
                                                                        line._id
                                                                ),
                                                        });
                                                    }
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        paddingRight: 2,
                                                    }}
                                                >
                                                    {state.production_lines.includes(
                                                        line._id
                                                    ) ? (
                                                        <MdRadioButtonChecked />
                                                    ) : (
                                                        <MdRadioButtonUnchecked />
                                                    )}
                                                </Box>
                                                {line.name}
                                            </ToggleButton>
                                        ))}
                                    </ToggleButtonGroup>
                                    <Box p={1.5} />
                                    <Typography>
                                        Are you using pre-packed trays?
                                    </Typography>
                                    <FormRow>
                                        <FormControlLabel
                                            label={
                                                state.use_trays ? 'Yes' : 'No'
                                            }
                                            control={
                                                <Switch
                                                    checked={state.use_trays}
                                                    onChange={(
                                                        e,
                                                        use_trays
                                                    ) => {
                                                        setState({
                                                            ...state,
                                                            use_trays,
                                                        });
                                                    }}
                                                />
                                            }
                                        />
                                    </FormRow>
                                    <Box p={1} />
                                    <Tooltip arrow title={holdup || ''}>
                                        <Box>
                                            <LoadingButton
                                                fullWidth
                                                disabled={Boolean(holdup)}
                                                loading={loading}
                                                variant="contained"
                                                onClick={() => create()}
                                            >
                                                {"Let's go!"}
                                            </LoadingButton>
                                        </Box>
                                    </Tooltip>
                                </Box>
                            </Collapse>
                        </Box>
                    </Collapse>
                </Box>
                <Collapse in={!product}>
                    <Box
                        sx={{
                            ...shape,
                            background: palette.background.paper,
                            overflow: 'hidden',
                            p: 1,
                        }}
                    >
                        <SearchInput
                            fullWidth
                            variant="standard"
                            InputProps={{ disableUnderline: true }}
                            placeholder="Or search manually"
                            value={filter.name || ''}
                            onChange={(e) =>
                                setFilter({ ...filter, name: e || '' })
                            }
                        />
                    </Box>
                </Collapse>
            </Box>
        </Box>
    );
};

export default LaunchSession;
