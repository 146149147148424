import React, { ReactElement } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import BatchReports from './components/BatchReports';
import Mixing from './components/Mixing';
import MixingCardForm from './components/MixingSchedule/components/MixingCardForm';
import MixingSchedule from './components/MixingSchedule';
import Palletizing from './components/Palletizing';
import BatchForm from './components/Mixing/components/BatchForm';
import BatchDetail from './components/BatchDetail';

const Production = (): ReactElement => {
    return (
        <Routes>
            <Route path="batchreports" element={<BatchReports />} />
            <Route path="batchreports/:id" element={<BatchDetail />} />
            <Route path="mixing" element={<Mixing />} />
            <Route path="mixing/:id" element={<BatchForm />} />
            <Route path="schedule" element={<MixingSchedule />} />
            <Route path="schedule/:plant/create" element={<MixingCardForm />} />
            <Route path="schedule/card/:id" element={<MixingCardForm />} />
            <Route path="palletizing/*" element={<Palletizing />} />
        </Routes>
    );
};

export default Production;
