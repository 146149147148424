import { useTheme } from '@mui/material/';
import { Box } from '@mui/system';
import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import Message from '../../../../../../components/feedback/Message';
import { useBatch } from '../../../../../../graphql/Batch/operations/useBatch';
import { useRecipeVersion } from '../../../../../../graphql/RecipeVersion/operations/useRecipeVersion';
import { SnackbarProvider } from '../../../../../../providers/SnackbarProvider';
import BatchRecipe from './components/BatchRecipe';

const BatchForm = (): ReactElement => {
    const { palette } = useTheme();

    const { id } = useParams();

    const { data, error, loading } = useBatch({
        variables: {
            id: id || '',
        },
        skip: !id,
    });

    const {
        data: recipeData,
        error: recipeError,
        loading: recipeLoading,
    } = useRecipeVersion({
        variables: {
            id: data ? data.batch.recipe_version._id : '',
        },
        skip: !data,
    });

    return (
        <SnackbarProvider duration={4000}>
            <Box
                sx={{
                    height: '100vh',
                    display: 'flex',
                    alignItems: 'stretch',
                    flexFlow: 'column',
                    color: palette.text.primary,
                    background: palette.background.default,
                }}
            >
                {loading || recipeLoading ? (
                    <Message type="Loading" />
                ) : error || recipeError || !data || !recipeData ? (
                    <Message type="Error" error={error} />
                ) : (
                    <BatchRecipe
                        batch={data.batch}
                        recipe={recipeData.recipeVersion}
                    />
                )}
            </Box>
        </SnackbarProvider>
    );
};

export default BatchForm;
