import React from 'react';
import { Skeleton, useTheme, Box, ButtonBase } from '@mui/material/';
import { ReactElement } from 'react';
import { useReceiptLine } from '../../../graphql/ReceiptLine/ReceiptLine';
import { Document, Page, pdfjs } from 'react-pdf';
import { base64ToBlob } from '../../../utils/base64ToBlob';
import printJS from 'print-js';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export interface ReceiptLineDocumentProps {
    id: string;
}

const ReceiptLineDocument = (props: ReceiptLineDocumentProps): ReactElement => {
    const { id } = props;
    const { palette, typography } = useTheme();

    const { data, error, loading } = useReceiptLine({ variables: { id } });

    const [numPages, setNumPages] = React.useState<number | null>(null);
    const [pageNumber, setPageNumber] = React.useState(1);
    const { shape } = useTheme();

    const line = data ? data.receipt_line : null;

    const open = () => {
        if (line) {
            const fileUrl = URL.createObjectURL(
                base64ToBlob(line.line.document.data, 'application/pdf')
            );
            printJS(fileUrl);
        }
    };

    if (!line) return <Skeleton sx={{ width: '400px', height: '300px' }} />;

    return (
        <Box>
            <ButtonBase
                sx={{
                    display: 'inline-block',
                    ...shape,
                    overflow: 'hidden',
                }}
                onClick={() => open()}
            >
                <Document
                    file={`data:application/pdf;base64,${line.line.document.data}`}
                    onLoadSuccess={({ numPages }) => {
                        setNumPages(numPages);
                    }}
                >
                    <Page pageNumber={pageNumber} />
                </Document>
            </ButtonBase>
        </Box>
    );
};

export default ReceiptLineDocument;
