import {
    Autocomplete,
    Box,
    Chip,
    MenuItem,
    Stack,
    TextField,
    TextFieldProps,
    useTheme,
} from '@mui/material';
import React, { ReactElement } from 'react';
import { useTinyProfiles } from '../../../../graphql/Profile/operations/useProfiles';
import { TinyProfile } from '../../../../graphql/Profile/Profile';

export type ProfileFieldProps<M extends boolean> = M extends true
    ? Omit<TextFieldProps, 'value' | 'onChange' | 'multiple'> & {
          multiple: M;
          value: string[];
          onChange: (value: string[]) => void;
      }
    : Omit<TextFieldProps, 'value' | 'onChange' | 'multiple'> & {
          multiple: M;
          value: string;
          onChange: (value: string) => void;
      };

const ProfileField = <M extends boolean>(
    props: ProfileFieldProps<M>
): ReactElement => {
    const { value, multiple, onChange, ...rest } = props;

    const { palette } = useTheme();

    const { data, error, loading } = useTinyProfiles({
        variables: {
            filter: {
                skip: 0,
                take: 200,
            },
        },
    });

    const profiles = data ? data.profiles.items : [];

    if (multiple)
        return (
            <Autocomplete
                multiple
                fullWidth={rest.fullWidth}
                options={profiles}
                isOptionEqualToValue={(profile, value) => {
                    return profile.user_id === value.user_id;
                }}
                freeSolo
                value={profiles.filter((val) => value.includes(val.user_id))}
                onChange={(v, newVal) => {
                    onChange((newVal as TinyProfile[]).map((v) => v.user_id));
                }}
                renderOption={(props, op) => (
                    <MenuItem {...props}>{op.name}</MenuItem>
                )}
                renderTags={(val) => (
                    <Stack
                        sx={{ paddingTop: 1, paddingBottom: 1 }}
                        direction="row"
                        gap={1}
                    >
                        {val.map((comp) => {
                            return (
                                <Chip
                                    key={'op_' + comp.user_id}
                                    label={comp.name}
                                    onDelete={() =>
                                        onChange(
                                            value.filter(
                                                (v) => v !== comp.user_id
                                            )
                                        )
                                    }
                                />
                            );
                        })}
                    </Stack>
                )}
                renderInput={(params) => (
                    <TextField {...rest} {...params} variant="filled" />
                )}
            />
        );

    return (
        <TextField
            {...rest}
            value={value || 'null'}
            onChange={(e) => onChange(e.target.value || '')}
            variant={rest.variant || 'filled'}
            select
            InputProps={{
                ...rest.InputProps,
                sx: !value
                    ? {
                          color: palette.text.disabled,
                          ...rest.InputProps?.sx,
                      }
                    : { ...rest.InputProps?.sx },
            }}
        >
            <MenuItem value={'null'} disabled>
                Profile
            </MenuItem>
            {profiles.map((profile) => (
                <MenuItem key={'op_' + profile.user_id} value={profile.user_id}>
                    {profile.name}
                </MenuItem>
            ))}
        </TextField>
    );
};

export default ProfileField;
