import {
    Box,
    Button,
    IconButton,
    InputAdornment,
    LinearProgress,
    ListItemAvatar,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Typography,
    useTheme,
} from '@mui/material/';
import React, { ReactElement } from 'react';
import {
    MdArrowLeft,
    MdDelete,
    MdEmail,
    MdLock,
    MdLockOpen,
    MdPrint,
} from 'react-icons/md';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import CodeField from '../../../../../../../../../../components/inputs/FieldInputs/CodeField';
import CompanyField from '../../../../../../../../../../components/inputs/FieldInputs/CompanyField';
import { CodeType } from '../../../../../../../../../../graphql/Code/useCode';
import {
    UpdateOrderArgs,
    useOrderUpdate,
} from '../../../../../../../../../../graphql/Order/operations/useOrderUpdate';
import { Order } from '../../../../../../../../../../graphql/Order/Order';
import ResponsiveDialog from '../../../../../../../../../../components/Layout/ResponsiveDialog';
import CarefullButton from '../../../../../../../../../../components/inputs/Buttons/CarefulButton';

export interface OrderHeaderProps {
    children: Order;
    locked: boolean;
    setLocked: (locked: boolean) => void;
}

const threshold = 1200;

const OrderHeader = (props: OrderHeaderProps): ReactElement => {
    const { children: order, locked, setLocked } = props;

    const nav = useNavigate();

    const { palette, typography, shape } = useTheme();

    const [focus, setFocus] = React.useState<(Element & EventTarget) | null>(
        null
    );

    const [deletion, setDeletion] = React.useState(false);

    const [state, setState] = React.useState<UpdateOrderArgs>({
        id: order._id,
        data: {
            code: order.code,
            customer: order.customer ? order.customer._id : null,
            vendor: order.vendor ? order.vendor._id : null,
        },
    });

    const [update, { error: updateError, loading: updateLoading }] =
        useOrderUpdate({ variables: state });

    const [timer, setTimer] = React.useState<number | null>(null);

    React.useEffect(() => {
        if (timer !== null && timer !== 0) {
            const timeout = setTimeout(() => {
                setTimer((t) => (t as number) - threshold / 5);
            }, threshold / 5);

            return () => clearTimeout(timeout);
        }
    }, [timer]);

    React.useEffect(() => {
        if (timer == 0) {
            update();
        }
    }, [timer]);

    const ready = !(timer || updateLoading);

    const handleChange = (data: Partial<UpdateOrderArgs['data']>) => {
        setState({ ...state, data: { ...state.data, ...data } });
        setTimer(threshold);
    };

    return (
        <Box
            sx={{
                background: palette.background.paper,
                p: 2,
                position: 'relative',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingRight: 4,
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ paddingTop: 1.5 }}>
                    <IconButton onClick={() => setLocked(!locked)}>
                        {locked ? <MdLock /> : <MdLockOpen />}
                    </IconButton>
                </Box>
                <Box sx={{ maxWidth: 300 }}>
                    <CodeField
                        disabled={locked}
                        variant="standard"
                        InputProps={{
                            sx: { ...typography.crisp, fontSize: '2rem' },
                            disableUnderline: true,
                        }}
                        type={CodeType.PO}
                        label="PO"
                        value={state.data.code || ''}
                        onChange={(code) =>
                            handleChange({ code: code || null })
                        }
                    />
                </Box>
            </Box>
            <Box sx={{ display: 'flex', gap: 2 }}>
                <Box sx={{ display: 'flex', flexFlow: 'column' }}>
                    <CompanyField
                        variant="standard"
                        disabled={locked}
                        multiple={false}
                        value={state.data.vendor || ''}
                        onChange={(val) => handleChange({ vendor: val })}
                        InputProps={{
                            disableUnderline: true,
                            startAdornment: (
                                <InputAdornment position="start">
                                    Vendor:
                                </InputAdornment>
                            ),
                        }}
                    />
                    <CompanyField
                        variant="standard"
                        disabled={locked}
                        multiple={false}
                        value={state.data.customer || ''}
                        onChange={(val) => handleChange({ customer: val })}
                        InputProps={{
                            disableUnderline: true,
                            startAdornment: (
                                <InputAdornment position="start">
                                    Customer:
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
                <Box>
                    <IconButton onClick={(e) => setFocus(e.currentTarget)}>
                        <BsThreeDotsVertical />
                    </IconButton>
                </Box>
            </Box>
            {updateLoading && (
                <Box
                    sx={{ position: 'absolute', left: 0, right: 0, bottom: 0 }}
                >
                    <LinearProgress />
                </Box>
            )}
            <Menu
                open={Boolean(focus)}
                anchorEl={focus}
                onClose={() => setFocus(null)}
            >
                <MenuItem onClick={() => setDeletion(true)}>
                    <ListItemIcon sx={{ margin: 0, padding: 0 }}>
                        <MdDelete />
                    </ListItemIcon>
                    <ListItemText primary="Delete order" />
                </MenuItem>
                <MenuItem>
                    <ListItemIcon sx={{ margin: 0, padding: 0 }}>
                        <MdPrint />
                    </ListItemIcon>
                    <ListItemText primary="Print PO" />
                </MenuItem>
                <MenuItem>
                    <ListItemIcon sx={{ margin: 0, padding: 0 }}>
                        <MdEmail />
                    </ListItemIcon>
                    <ListItemText primary="Draft an email" />
                </MenuItem>
            </Menu>
            <ResponsiveDialog
                open={deletion}
                onClose={() => {
                    setDeletion(false);
                    setFocus(null);
                }}
            >
                <Typography variant="h6">
                    Are you sure you want to delete this order?
                </Typography>
                <Box sx={{ display: 'flex', gap: 2, paddingTop: 2 }}>
                    <Button
                        fullWidth
                        color="inherit"
                        startIcon={<MdArrowLeft />}
                        onClick={() => {
                            setDeletion(false);
                            setFocus(null);
                        }}
                    >
                        No, go back
                    </Button>
                    <CarefullButton
                        fullWidth
                        onClick={() => {
                            update({
                                variables: {
                                    ...state,
                                    data: { ...state.data, deleted: true },
                                },
                                onCompleted: () => nav('/supplychain/orders'),
                            });
                        }}
                    >
                        Yes, delete forever
                    </CarefullButton>
                </Box>
            </ResponsiveDialog>
        </Box>
    );
};

export default OrderHeader;
