import { Button, TableBody, Theme, Tooltip, useTheme } from '@mui/material';
import {
    Box,
    SxProps,
    Table,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import React, { ReactElement } from 'react';
import AppNav from '../../../../components/Layout/AppNav/components';
import NavContent from '../../../../components/Layout/AppNav/components/NavContent';
import NavHeader from '../../../../components/Layout/NavHeader';
import { useOrderQueue } from '../../../../graphql/OrderQueue/operations/useOrderQueue';
import { useOrderQueueTemplates } from '../../../../graphql/OrderQueue/operations/useOrderQueueTemplates';
import { OrderQueueLine } from '../../../../graphql/OrderQueue/OrderQueueLine';
import QueueTemplates from './components/QueueTemplates';
import ItemDrawer from './components/ItemDrawer';
import { OrderQueueLineInput } from '../../../../graphql/OrderQueue/inputs/OrderQueueLineInput';
import QueueLine from './components/QueueLine';
import { useOrderQueueUpdate } from '../../../../graphql/OrderQueue/operations/useOrderQueueUpdate';
import { OperationResult } from '../../../../utils/types/OperationResult';
import { useOrderQueueProcess } from '../../../../graphql/OrderQueue/operations/useOrderQueueProcess';
import Message from '../../../../components/feedback/Message';
import { MdCheck, MdEmail } from 'react-icons/md';
import { useOurCompany } from '../../../../graphql/Company/operations/useOurCompany';
import { LoadingButton } from '@mui/lab';
import { FulfillmentEvents } from '../../../../graphql/FulfillmentEvent/useFulfillmentEvents';
import { useEmails } from './useEmails';
import { useClickState } from '../../../../hooks/useClickState';
import QueueDrafts from './components/QueueDrafts';

const threshold = 1200;

const OrderQueue = (): ReactElement => {
    const [queue, setQueue] = React.useState<OrderQueueLine[]>([]);

    const { palette } = useTheme();

    const { data: ourCompanyData, error: companyError } = useOurCompany();

    const ldb = ourCompanyData ? ourCompanyData.ourCompany : null;

    const {
        data: templateData,
        loading: templateLoading,
        error: templateError,
    } = useOrderQueueTemplates();

    const { error, loading } = useOrderQueue({
        fetchPolicy: 'network-only',
        onCompleted: ({ orderQueue }) =>
            setQueue(
                orderQueue.map(
                    ({
                        po,
                        contents,
                        customer,
                        customer_location,
                        vendor,
                        vendor_location,
                        date,
                        time,
                    }) => ({
                        po,
                        contents: contents.map(({ item, unit, quantity }) => ({
                            item,
                            unit,
                            quantity,
                        })),
                        customer,
                        customer_location,
                        vendor,
                        vendor_location,
                        date,
                        time,
                    })
                )
            ),
    });

    const [update, { error: updateError, loading: updateLoading }] =
        useOrderQueueUpdate({
            variables: {
                data: queue,
            },
        });

    const [result, setResult] = React.useState<OperationResult<{
        processOrderQueue: boolean;
    }> | null>(null);

    const [process, { loading: processLoading }] = useOrderQueueProcess({
        onCompleted: (data) => setResult({ success: true, data }),
        onError: (error) => setResult({ success: false, error }),
        variables: {
            data: queue,
        },
        refetchQueries: [FulfillmentEvents],
    });

    const [timer, setTimer] = React.useState<number | null>(null);

    React.useEffect(() => {
        if (timer !== null && timer !== 0) {
            const timeout = setTimeout(() => {
                setTimer((t) => (t as number) - threshold / 5);
            }, threshold / 5);

            return () => clearTimeout(timeout);
        }
    }, [timer]);

    React.useEffect(() => {
        if (timer == 0) {
            update();
        }
    }, [timer]);

    const ready = !(timer || loading);

    const templates = templateData ? templateData.orderQueueTemplates : [];

    const shaded: SxProps<Theme> = { background: palette.tonal };
    const basic: SxProps<Theme> = { background: palette.background.paper };

    const checkLine = (line: OrderQueueLineInput): string | null => {
        if (!line.customer) return 'Each order needs a customer';
        if (!line.customer_location && ldb && ldb._id == line.customer)
            return 'Each Order to or from LDB needs a plant selection.';
        if (!line.date) return 'Each order needs a delivery date';
        if (!line.po) return 'Each order needs a PO';
        if (!line.vendor) return 'Each order needs a vendor';
        if (ldb !== null && line.vendor === ldb._id && !line.vendor_location)
            return 'Each order from LDB needs a plant origin.';

        return null;
    };

    const getHoldup = () => {
        if (!ready) return 'Save in progress...';
        if (queue.length == 0) return 'Add an item to your queue';
        const lines = queue.map((q) => checkLine(q));
        if (lines.some((line) => line !== null))
            return lines.find((l) => l !== null);
        return null;
    };

    const holdup = getHoldup();

    return (
        <AppNav
            error={error || templateError || companyError}
            loading={loading || templateLoading}
        >
            {result && result.success == false ? (
                <Message
                    type="Error"
                    action={
                        <Button onClick={() => setResult(null)}>
                            Try again
                        </Button>
                    }
                >
                    {result.error.message}
                </Message>
            ) : result ? (
                <Message
                    type="Success"
                    onComplete={() => {
                        setQueue([]);

                        setResult(null);
                        setTimer(null);
                    }}
                >
                    Orders Processed!
                </Message>
            ) : (
                <NavContent padding={{ header: 3, content: 0, footer: 0 }}>
                    {{
                        header: (
                            <NavHeader back={['Orders', '/supplychain/orders']}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexFlow: 'column',
                                        gap: 0.5,
                                    }}
                                >
                                    <Typography variant="crisp">
                                        Order Queue
                                    </Typography>
                                    {!ready ? (
                                        <Typography
                                            variant="caption"
                                            sx={{
                                                color: palette.text.secondary,
                                            }}
                                        >
                                            Save in progress
                                        </Typography>
                                    ) : (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 0.5,
                                                color: palette.success.main,
                                            }}
                                        >
                                            <Typography variant="caption">
                                                Saved
                                            </Typography>
                                            <MdCheck />
                                        </Box>
                                    )}
                                </Box>
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                    <QueueDrafts>{queue}</QueueDrafts>
                                    <Box>
                                        <ItemDrawer
                                            onClick={(item) => {
                                                const newLine: OrderQueueLineInput =
                                                    {
                                                        po: null,
                                                        contents: [
                                                            {
                                                                item: item._id,
                                                                unit: null,
                                                                quantity: null,
                                                            },
                                                        ],
                                                        customer: null,
                                                        vendor: null,
                                                        customer_location: null,
                                                        vendor_location: null,
                                                        date: null,
                                                        time: null,
                                                    };

                                                setQueue([...queue, newLine]);
                                                setTimer(threshold);
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </NavHeader>
                        ),
                        content: (
                            <Box
                                sx={{
                                    height: '100%',
                                    overflow: 'auto',
                                    paddingLeft: 3,
                                    paddingRight: 3,
                                }}
                            >
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ ...shaded }}>
                                                Item
                                            </TableCell>
                                            <TableCell sx={{ ...shaded }}>
                                                Quantity
                                            </TableCell>
                                            <TableCell sx={{ ...basic }}>
                                                Customer
                                            </TableCell>
                                            <TableCell sx={{ ...basic }}>
                                                Vendor
                                            </TableCell>
                                            <TableCell sx={{ ...basic }}>
                                                PO
                                            </TableCell>
                                            <TableCell sx={{ ...basic }}>
                                                Deliver by
                                            </TableCell>
                                            <TableCell sx={{ ...basic }}>
                                                Time
                                            </TableCell>
                                            <TableCell
                                                sx={{ ...basic }}
                                            ></TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {queue.map((line, index) => (
                                            <QueueLine
                                                index={index}
                                                key={'line_' + index}
                                                value={line}
                                                onChange={(l) => {
                                                    const copy = [...queue];
                                                    if (l) {
                                                        copy[index] = l;
                                                        setQueue(copy);
                                                        setTimer(threshold);
                                                    } else {
                                                        copy.splice(index, 1);
                                                        setQueue(copy);
                                                        setTimer(threshold);
                                                    }
                                                }}
                                                touch={() =>
                                                    setTimer(threshold)
                                                }
                                            />
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        ),
                        footer: (
                            <Box
                                sx={{
                                    padding: 3,
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                {/* <QueueTemplates>{templates}</QueueTemplates> */}
                                <Box />
                                <Tooltip title={holdup || ''} arrow>
                                    <Box>
                                        <LoadingButton
                                            disabled={Boolean(holdup)}
                                            onClick={() => {
                                                process();
                                            }}
                                            endIcon={<MdCheck />}
                                            variant="contained"
                                            size="large"
                                            loading={processLoading}
                                            color="success"
                                        >
                                            Process Orders
                                        </LoadingButton>
                                    </Box>
                                </Tooltip>
                            </Box>
                        ),
                    }}
                </NavContent>
            )}
        </AppNav>
    );
};

export default OrderQueue;
