import { gql } from '@apollo/client';
import { getMutationHook } from '../../types';

const PalletCreation = gql`
    mutation CreatePallet($id: ObjectId!) {
        createPallet(id: $id) {
            _id
        }
    }
`;

export interface CreatePalletRes {
    createPallet: { _id: string };
}

export interface CreatePalletArgs {
    id: string;
}

export const usePalletCreation = getMutationHook<
    CreatePalletRes,
    CreatePalletArgs
>(PalletCreation);
