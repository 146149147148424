import { Box, TextField, Tooltip, Typography } from '@mui/material';
import React, { ReactElement } from 'react';
import { MdCheck, MdDelete, MdRefresh } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import FormResult from '../../../../../../components/feedback/FormResult';
import CarefullButton from '../../../../../../components/inputs/Buttons/CarefulButton';
import SuccessButton from '../../../../../../components/inputs/Buttons/SuccessButton';
import ItemTypeInput from '../../../../../../components/inputs/FieldInputs/ItemTypeInput';
import UnitClassInput from '../../../../../../components/inputs/FieldInputs/UnitClassInput';
import AppNav from '../../../../../../components/Layout/AppNav/components';
import NavContent from '../../../../../../components/Layout/AppNav/components/NavContent';
import FormRow from '../../../../../../components/Layout/FormRow';
import NavHeader from '../../../../../../components/Layout/NavHeader';
import { ItemType } from '../../../../../../graphql/Item/Item';
import { useUnit } from '../../../../../../graphql/Unit/operations/useUnit';
import {
    CreateUnitArgs,
    CreateUnitRes,
    useUnitCreation,
} from '../../../../../../graphql/Unit/operations/useUnitCreation';
import {
    UpdateUnitArgs,
    UpdateUnitRes,
    useUnitUpdate,
} from '../../../../../../graphql/Unit/operations/useUnitUpdate';
import { permissableUnits } from '../../../../../../graphql/Unit/permissableUnits';
import { UnitClass } from '../../../../../../graphql/Unit/UnitClass';
import { OperationResult } from '../../../../../../utils/types/OperationResult';

const UnitForm = (): ReactElement => {
    const { id, item_type } = useParams();
    const nav = useNavigate();

    const [state, setState] = React.useState<UpdateUnitArgs | CreateUnitArgs>({
        data: {
            name: '',
            plural: '',
            spanish_name: '',
            spanish_plural: '',
            class: UnitClass.Weight,
            base_per_unit: 1,
            item_type: item_type
                ? (item_type as ItemType)
                : ItemType.Ingredient,
        },
    });

    const { data } = useUnit({
        variables: { id: id || '' },
        skip: !id,
        fetchPolicy: 'network-only',
        onCompleted: ({ unit }) => {
            setState({
                id: unit._id,
                data: {
                    name: unit.name,
                    plural: unit.plural,
                    spanish_name: unit.spanish_name,
                    spanish_plural: unit.spanish_plural,
                    class: unit.class,
                    base_per_unit: unit.base_per_unit,
                    item_type: unit.item_type,
                },
            });
        },
    });

    const [result, setResult] = React.useState<null | OperationResult<
        CreateUnitRes | UpdateUnitRes
    >>(null);

    const [handleCreate, { loading: createLoading }] = useUnitCreation({
        variables: 'id' in state ? undefined : state,
        onCompleted: (data) => setResult({ success: true, data }),
        onError: (error) => setResult({ success: false, error }),
    });

    const [handleUpdate, { loading: updateLoading }] = useUnitUpdate({
        variables:
            'id' in state ? { ...state, id: id || state.id || '' } : undefined,
        onCompleted: (data) => setResult({ success: true, data }),
        onError: (error) => setResult({ success: false, error }),
    });

    const getHoldup = (): string | null => {
        if (!state.data.name) return 'Please enter a unit name.';
        if (!state.data.spanish_name) return 'Please enter a spanish name.';
        if (!state.data.plural) return 'Please enter a plural name.';
        if (!state.data.spanish_plural)
            return 'Please enter a spanish plural name.';
        if (!state.data.base_per_unit) return 'Please enter a qty per unit.';
        return null;
    };

    return (
        <AppNav loading={updateLoading || createLoading}>
            {result ? (
                <FormResult
                    entity="Unit"
                    clear={() => setResult(null)}
                    onComplete={() =>
                        nav(`/library/units/${state.data.item_type}`)
                    }
                >
                    {result}
                </FormResult>
            ) : (
                <NavContent>
                    {{
                        header: (
                            <NavHeader
                                back={
                                    data
                                        ? [
                                              data.unit.name,
                                              `/library/units/unit/${data.unit._id}`,
                                          ]
                                        : ['Units', '/library/units']
                                }
                            >
                                <Typography variant="crisp">
                                    {id && !data
                                        ? ''
                                        : data
                                        ? `Update ${data.unit.name}`
                                        : 'New Unit'}
                                </Typography>
                                <Box />
                            </NavHeader>
                        ),
                        content: (
                            <Box sx={{ maxWidth: 600 }}>
                                <FormRow>
                                    <TextField
                                        autoFocus
                                        fullWidth
                                        label="Name"
                                        value={state.data.name}
                                        onChange={(e) =>
                                            setState({
                                                ...state,
                                                data: {
                                                    ...state.data,
                                                    name: e.target.value,
                                                },
                                            })
                                        }
                                    />
                                    <TextField
                                        fullWidth
                                        label="Plural Name"
                                        value={state.data.plural}
                                        onChange={(e) =>
                                            setState({
                                                ...state,
                                                data: {
                                                    ...state.data,
                                                    plural: e.target.value,
                                                },
                                            })
                                        }
                                    />
                                </FormRow>
                                <FormRow>
                                    <TextField
                                        fullWidth
                                        label="Spanish Name"
                                        value={state.data.spanish_name}
                                        onChange={(e) =>
                                            setState({
                                                ...state,
                                                data: {
                                                    ...state.data,
                                                    spanish_name:
                                                        e.target.value,
                                                },
                                            })
                                        }
                                    />
                                    <TextField
                                        fullWidth
                                        label="Spanish Plural"
                                        value={state.data.spanish_plural}
                                        onChange={(e) =>
                                            setState({
                                                ...state,
                                                data: {
                                                    ...state.data,
                                                    spanish_plural:
                                                        e.target.value,
                                                },
                                            })
                                        }
                                    />
                                </FormRow>
                                <FormRow>
                                    <ItemTypeInput
                                        fullWidth
                                        label="Item Type"
                                        value={state.data.item_type}
                                        onChange={(item_type) => {
                                            setState({
                                                ...state,
                                                data: {
                                                    ...state.data,
                                                    item_type,
                                                    class: permissableUnits[
                                                        item_type
                                                    ][0],
                                                },
                                            });
                                        }}
                                    />
                                    <UnitClassInput
                                        item_type={
                                            state.data.item_type || undefined
                                        }
                                        disabled={
                                            !state.data.item_type ||
                                            permissableUnits[
                                                state.data.item_type
                                            ].length <= 1
                                        }
                                        fullWidth
                                        label="Unit Class"
                                        value={state.data.class}
                                        onChange={(uClass) => {
                                            setState({
                                                ...state,
                                                data: {
                                                    ...state.data,
                                                    class: uClass,
                                                },
                                            });
                                        }}
                                    />
                                </FormRow>
                                <FormRow>
                                    <TextField
                                        fullWidth
                                        value={
                                            state.data.base_per_unit === null
                                                ? ''
                                                : state.data.base_per_unit
                                        }
                                        onChange={(e) => {
                                            const parsed = parseFloat(
                                                e.target.value
                                            );

                                            setState({
                                                ...state,
                                                data: {
                                                    ...state.data,
                                                    base_per_unit: isNaN(parsed)
                                                        ? null
                                                        : parsed,
                                                },
                                            });
                                        }}
                                        label={`${
                                            state.data.class == UnitClass.Count
                                                ? state.data.item_type ==
                                                  ItemType.Product
                                                    ? 'Boxes'
                                                    : 'Count'
                                                : state.data.class ==
                                                  UnitClass.Length
                                                ? 'Feet'
                                                : state.data.class ===
                                                  UnitClass.Volume
                                                ? 'Gallons'
                                                : state.data.class ===
                                                  UnitClass.Weight
                                                ? 'Pounds'
                                                : ''
                                        } per ${state.data.name}`}
                                    />
                                </FormRow>
                                <FormRow>
                                    <SuccessButton
                                        holdup={getHoldup()}
                                        success={null}
                                        onSuccess={() => null}
                                        endIcon={<MdCheck />}
                                        onClick={() => {
                                            if ('id' in state) {
                                                handleUpdate();
                                            } else {
                                                handleCreate();
                                            }
                                        }}
                                    >
                                        Save
                                    </SuccessButton>
                                    {data && (
                                        <CarefullButton
                                            endIcon={
                                                data.unit.deletion ? (
                                                    <MdRefresh />
                                                ) : (
                                                    <MdDelete />
                                                )
                                            }
                                            onClick={() => {
                                                handleUpdate({
                                                    variables: {
                                                        id: data.unit._id,
                                                        data: {
                                                            name: data.unit
                                                                .name,
                                                            plural: data.unit
                                                                .plural,
                                                            spanish_name:
                                                                data.unit
                                                                    .spanish_name,
                                                            spanish_plural:
                                                                data.unit
                                                                    .spanish_plural,
                                                            class: data.unit
                                                                .class,
                                                            base_per_unit:
                                                                data.unit
                                                                    .base_per_unit,
                                                            item_type:
                                                                data.unit
                                                                    .item_type,
                                                            deleted: true,
                                                        },
                                                    },
                                                    onCompleted: (data) =>
                                                        setResult({
                                                            success: true,
                                                            data,
                                                        }),
                                                    onError: (error) =>
                                                        setResult({
                                                            success: false,
                                                            error,
                                                        }),
                                                });
                                            }}
                                        >
                                            {data.unit.deletion
                                                ? 'Restore Unit'
                                                : 'Delete Unit'}
                                        </CarefullButton>
                                    )}
                                </FormRow>
                            </Box>
                        ),
                    }}
                </NavContent>
            )}
        </AppNav>
    );
};

export default UnitForm;
