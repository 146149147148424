import { Box, Button } from '@mui/material/';
import Typography from '@mui/material/Typography';
import React, { ReactElement } from 'react';
import { MdOutlineArrowForward } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import CarefullButton from '../../../../../../components/inputs/Buttons/CarefulButton';
import { Batch } from '../../../../../../graphql/Batch/Batch';
import { BatchListQuery } from '../../../../../../graphql/Batch/operations/useBatches';
import {
    UpdateBatchArgs,
    useBatchUpdate,
} from '../../../../../../graphql/Batch/operations/useBatchUpdate';
import { MixingCardQuery } from '../../../../../../graphql/MixingCard/operations/useMixingCard';
import { MixingCardsQuery } from '../../../../../../graphql/MixingCard/operations/useMixingCards';
import { useProfileContext } from '../../../../../../providers/ProfileProvider';

export interface BatchEntryProps {
    batch: Batch;
}

export const getStateFromBatch = (batch: Batch): UpdateBatchArgs['data'] => {
    return {
        lines: batch.lines.map((line) => ({
            recipe_step: line.recipe_step,
            content: {
                quantity: line.content.client_qty,
                unit: line.content.client_unit._id,
                lot: line.content.lot._id,
                receipt_line: line.content.receipt_line,
            },
        })),
    };
};

const BatchEntry = (props: BatchEntryProps): ReactElement => {
    const { batch } = props;

    const nav = useNavigate();

    const { data: profileData } = useProfileContext();

    const [handleUpdate, { loading: updateLoading }] = useBatchUpdate();

    const abandon = () =>
        handleUpdate({
            variables: {
                id: batch._id,
                data: {
                    ...getStateFromBatch(batch),
                    date_abandoned: new Date(),
                },
            },
            refetchQueries: [MixingCardQuery, MixingCardsQuery, BatchListQuery],
        });

    return (
        <Box
            sx={{
                height: '100%',
                display: 'flex',
                flexFlow: 'column',
                justifyContent: 'center',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexFlow: 'column',
                    alignItems: 'center',
                    gap: 1,
                }}
            >
                <Typography>{`Hey ${
                    profileData
                        ? profileData.profile.given_name ||
                          profileData.profile.name
                        : ''
                },`}</Typography>
                <Typography variant="h4">
                    {`Let's finish this batch`}
                </Typography>
                <Box p={1} />
                <Button
                    onClick={() => nav(batch._id)}
                    endIcon={<MdOutlineArrowForward />}
                >{`Continue ${batch.item.name} Batch`}</Button>
                <Box p={6} />
                <Typography>or</Typography>
                <CarefullButton
                    loading={updateLoading}
                    onClick={() => {
                        abandon();
                    }}
                >
                    Abandon batch
                </CarefullButton>
            </Box>
        </Box>
    );
};

export default BatchEntry;
