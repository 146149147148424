import { LoadingButton } from '@mui/lab';
import {
    Box,
    Collapse,
    List,
    ListItemButton,
    ListItemText,
    TextField,
    Tooltip,
    useTheme,
} from '@mui/material/';
import Typography from '@mui/material/Typography';
import React, { ReactElement } from 'react';
import { MdCheck } from 'react-icons/md';
import { BatchDetailState } from '../..';
import ItemField from '../../../../../../../../components/inputs/FieldInputs/ItemField';
import SearchInput from '../../../../../../../../components/inputs/FieldInputs/SearchInput';
import UnitField from '../../../../../../../../components/inputs/FieldInputs/UnitField';
import FormRow from '../../../../../../../../components/Layout/FormRow';
import ResponsiveDialog from '../../../../../../../../components/Layout/ResponsiveDialog';
import { Batch } from '../../../../../../../../graphql/Batch/Batch';
import { useBatchUpdate } from '../../../../../../../../graphql/Batch/operations/useBatchUpdate';
import { ItemType } from '../../../../../../../../graphql/Item/Item';
import { LotFilter } from '../../../../../../../../graphql/Lot/LotFilter';
import { useTinyLots } from '../../../../../../../../graphql/Lot/useLots';
import { niceList } from '../../../../../../../../utils/niceList';
import { getStateFromBatch } from '../../../../../Mixing/components/BatchEntry';

export interface BatchLineFormProps {
    batch: Batch;
    state: BatchDetailState | null;
    setState: (data: BatchDetailState | null) => void;
}

const BatchLineForm = (props: BatchLineFormProps): ReactElement => {
    const { batch, state, setState } = props;

    const { palette, shape } = useTheme();

    const [filter, setFilter] = React.useState<LotFilter>({
        skip: 0,
        take: 25,
    });

    const { data, error, loading } = useTinyLots({
        variables: {
            filter: {
                ...filter,
                item_ids: state && state.item ? [state.item] : undefined,
            },
        },
        skip: !filter.code,
    });

    const getHoldup = (): string | null => {
        if (!state) return 'Not ready';
        if (!state.item) return 'Select an item';
        if (!state.unit) return 'Select a unit';
        if (!state.qty) return 'Enter a quantity';
        if (!state.lot) return 'Please select alot';
        return null;
    };

    const holdup = getHoldup();

    const [handleUpdate, { loading: updateLoading }] = useBatchUpdate();

    const handleClose = () => {
        setFilter({ skip: 0, take: 25 });
        setState(null);
    };

    const handleSubmit = () => {
        if (state && !holdup) {
            if (state.focus.type == 'step') {
                const data = getStateFromBatch(batch);
                data.lines.push({
                    recipe_step: state.focus.step._id,
                    content: {
                        lot: state.lot,
                        unit: state.unit,
                        quantity: state.qty || 0,
                        receipt_line: null,
                    },
                });
                handleUpdate({
                    variables: {
                        id: batch._id,
                        data,
                    },
                    onCompleted: () => handleClose(),
                });
            } else {
                const data = getStateFromBatch(batch);
                data.lines.push({
                    recipe_step: null,
                    content: {
                        lot: state.lot,
                        unit: state.unit,
                        quantity: state.qty || 0,
                        receipt_line: null,
                    },
                });
                handleUpdate({
                    variables: {
                        id: batch._id,
                        data,
                    },
                    onCompleted: () => handleClose(),
                });
            }
        }
    };

    return (
        <ResponsiveDialog
            PaperProps={{ sx: { background: palette.background.paper } }}
            open={Boolean(state)}
            onClose={() => setState(null)}
        >
            <Typography variant="h6">
                {!state
                    ? ''
                    : state.focus.type == 'crumb'
                    ? 'Add crumb lot to batch'
                    : `Add lot to step`}
            </Typography>
            {state && state.focus.type == 'step' && state.focus.step.content && (
                <Typography color="text.secondary">
                    {niceList(
                        state.focus.step.content.items.map((i) => i.name),
                        'or'
                    )}
                </Typography>
            )}
            <Box p={1} />
            <FormRow>
                <ItemField
                    label="Item"
                    fullWidth
                    multiple={false}
                    value={state ? state.item : ''}
                    ids={
                        state &&
                        state.focus.type == 'step' &&
                        state.focus.step.content
                            ? state.focus.step.content.items.map((i) => i._id)
                            : undefined
                    }
                    onChange={(item) => {
                        if (state) {
                            setState({ ...state, item });
                        }
                    }}
                />
            </FormRow>
            <Collapse in={state !== null && state.item !== ''}>
                <Box>
                    <FormRow>
                        <TextField
                            label="Qty"
                            type="number"
                            value={
                                state
                                    ? state.qty == null
                                        ? ''
                                        : state.qty
                                    : ''
                            }
                            onChange={(e) => {
                                const parsed = parseFloat(e.target.value);
                                if (state) {
                                    setState({
                                        ...state,
                                        qty: isNaN(parsed) ? null : parsed,
                                    });
                                }
                            }}
                        />
                        <UnitField
                            label="Unit"
                            fullWidth
                            multiple={false}
                            value={state ? state.unit : ''}
                            item_types={[ItemType.Ingredient, ItemType.Cookie]}
                            items={state ? [state.item] : undefined}
                            onChange={(unit) => {
                                if (state) {
                                    setState({ ...state, unit });
                                }
                            }}
                        />
                    </FormRow>
                    <FormRow>
                        <SearchInput
                            label="Lot Search"
                            fullWidth
                            value={filter.code || ''}
                            onChange={(e) => {
                                setFilter({ ...filter, code: e || '' });
                            }}
                        />
                    </FormRow>
                </Box>
            </Collapse>
            <Collapse in={Boolean(filter.code) && !loading}>
                {!data || data.lots.count == 0 ? (
                    <Box>
                        <Typography>No Lots found</Typography>
                    </Box>
                ) : (
                    <Box>
                        <Typography
                            sx={{ paddingLeft: 1 }}
                            variant="caption"
                            color="text.secondary"
                        >
                            Select a lot
                        </Typography>
                        <Box
                            sx={{
                                background: palette.tonal,
                                ...shape,
                                maxHeight: 200,
                                overflow: 'auto',
                            }}
                        >
                            <List disablePadding>
                                {(data ? data.lots.items : []).map(
                                    (lot, index) => (
                                        <ListItemButton
                                            divider
                                            key={'lot_' + lot._id}
                                            selected={
                                                state !== null &&
                                                state.lot == lot._id
                                            }
                                            onClick={() => {
                                                if (state) {
                                                    setState({
                                                        ...state,
                                                        lot:
                                                            state.lot == lot._id
                                                                ? ''
                                                                : lot._id,
                                                    });
                                                }
                                            }}
                                        >
                                            <ListItemText
                                                primary={lot.code}
                                                secondary={lot.company.name}
                                            />
                                        </ListItemButton>
                                    )
                                )}
                            </List>
                        </Box>
                        <Box sx={{ paddingTop: 2 }}>
                            <Tooltip title={holdup || ''} arrow>
                                <Box>
                                    <LoadingButton
                                        loading={loading || updateLoading}
                                        onClick={handleSubmit}
                                        variant="contained"
                                        disabled={Boolean(holdup)}
                                        fullWidth
                                        endIcon={<MdCheck />}
                                    >
                                        Add lot to batch
                                    </LoadingButton>
                                </Box>
                            </Tooltip>
                        </Box>
                    </Box>
                )}
            </Collapse>
        </ResponsiveDialog>
    );
};

export default BatchLineForm;
