import { gql } from '@apollo/client';
import { BaseFragment } from '../../Base/Base';
import { getMutationHook } from '../../types';
import {
    PackingSession,
    PackingSessionFragment,
    PackingSessionLotFragment,
} from '../PackingSession';
import { UpdatePackingSessionInput } from '../inputs/UpdatePackingSessionInput';

export const UpdatePackingSessionMutation = gql`
    ${PackingSessionFragment}
    ${BaseFragment}
    ${PackingSessionLotFragment}
    mutation UpdatePackingSessionMutation(
        $id: ObjectId!
        $data: UpdatePackingSessionInput!
    ) {
        updatePackingSession(id: $id, data: $data) {
            ...PackingSessionFragment
        }
    }
`;

export interface UpdatePackingSessionRes {
    updatePackingSession: PackingSession;
}

export interface UpdatePackingSessionArgs {
    id: string;
    data: UpdatePackingSessionInput;
}

export const usePackingSessionUpdate = getMutationHook<
    UpdatePackingSessionRes,
    UpdatePackingSessionArgs
>(UpdatePackingSessionMutation);
