import { Box, LinearProgress, useTheme } from '@mui/material/';
import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import Message from '../../../../../../components/feedback/Message';
import { usePackingSession } from '../../../../../../graphql/PackingSession/operations/usePackingSession';
import { SnackbarProvider } from '../../../../../../providers/SnackbarProvider';
import PalletizingTopBar from './components/PalletizingTopBar';
import SessionAction from './components/SessionAction';
import SessionLots from './components/SessionLots';

const PalletizingForm = (): ReactElement => {
    const { palette } = useTheme();
    const { id } = useParams();

    const { data, error, loading } = usePackingSession({
        variables: {
            id: id || '',
        },
    });

    const session = data ? data.packingSession : null;

    return (
        <SnackbarProvider duration={4000}>
            <Box
                sx={{
                    height: '100vh',
                    display: 'flex',
                    flexFlow: 'column',
                    color: palette.text.primary,
                    background: palette.background.default,
                }}
            >
                <PalletizingTopBar />
                {error ? (
                    <Message type="Error">{error.message}</Message>
                ) : (
                    <Box
                        sx={{
                            flex: 1,
                            position: 'relative',
                            display: 'flex',
                            alignItems: 'srtetch',
                            overflow: 'hidden',
                        }}
                    >
                        {loading && (
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                }}
                            >
                                <LinearProgress />
                            </Box>
                        )}
                        {session && <SessionLots session={session} />}
                        {session && <SessionAction session={session} />}
                        {/* {session && <SessionHistory session={session} />} */}
                    </Box>
                )}
            </Box>
        </SnackbarProvider>
    );
};

export default PalletizingForm;
