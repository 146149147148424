import React, { ReactElement } from 'react';
import { Route, Routes } from 'react-router-dom';
import Companies from './components/Companies';
import Cookies from './components/Cookies';
import Ingredients from './components/Ingredients';
import MiscItems from './components/MiscItems';
import Packages from './components/Packages';
import Products from './components/Products';
import Units from './components/Units';

const Library = (): ReactElement => {
    return (
        <Routes>
            <Route path="companies/*" element={<Companies />} />
            <Route path="cookies/*" element={<Cookies />} />
            <Route path="ingredients/*" element={<Ingredients />} />
            <Route path="miscitems/*" element={<MiscItems />} />
            <Route path="packaging/*" element={<Packages />} />
            <Route path="products/*" element={<Products />} />
            <Route path="units/*" element={<Units />} />
        </Routes>
    );
};

export default Library;
