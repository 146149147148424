import React, { ReactElement } from 'react';
import IconButton from '@mui/material/IconButton';
import { MdClear, MdDelete } from 'react-icons/md';
import {
    Box,
    TextField,
    TableCell,
    TableRow,
    useTheme,
    Button,
    Checkbox,
    Tooltip,
} from '@mui/material';
import { OrderQueueLineInput } from '../../../../../../graphql/OrderQueue/inputs/OrderQueueLineInput';
import UnitField from '../../../../../../components/inputs/FieldInputs/UnitField';
import CodeField from '../../../../../../components/inputs/FieldInputs/CodeField';
import { useOurCompany } from '../../../../../../graphql/Company/operations/useOurCompany';
import { DesktopDatePicker } from '@mui/lab';
import TimePicker from '../../../../../../components/inputs/FieldInputs/TimePicker';
import { useItemOrderPreference } from '../../../../../../hooks/useItemOrderPreference';
import { useTinyItems } from '../../../../../../graphql/Item/operations/useTinyItems';
import QueueLocationField from './components/QueueLocationField';
import { useClickState } from '../../../../../../hooks/useClickState';
import ItemPopover from '../../../../../../components/inputs/FieldInputs/ItemPopover';
import { CodeType, useCode } from '../../../../../../graphql/Code/useCode';
import CompanyField from '../../../../../../components/inputs/FieldInputs/CompanyField';
import { BsArrow90DegDown } from 'react-icons/bs';

export interface QueueLineProps {
    value: OrderQueueLineInput;
    onChange: (value: OrderQueueLineInput | null) => void;
    index: number;
    touch: () => void;
}

const QueueLine = (props: QueueLineProps): ReactElement => {
    const { value, onChange: parentOnChange, touch, index } = props;

    const [itemFocus, setItemFocus] = useClickState();

    const { data: ourCompanyData, error: companyError } = useOurCompany();

    const ldb = ourCompanyData ? ourCompanyData.ourCompany : null;

    const onChange = (value: OrderQueueLineInput | null) => {
        touch();
        parentOnChange(value);
    };

    useItemOrderPreference(
        value.contents[0]?.item || '',
        !value.contents[0] ||
            !value.contents[0].item ||
            value.contents[0].quantity !== null ||
            value.contents[0].unit !== null ||
            value.vendor !== null ||
            value.contents.length !== 1,
        (pref) => {
            if (pref.item.order_preference) {
                if (value.contents.length == 1) {
                    const contentMatch =
                        pref.item.order_preference.contents.find(
                            (c) => c.item === value.contents[0].item
                        );

                    if (contentMatch) {
                        onChange({
                            ...value,
                            contents: [
                                {
                                    item: contentMatch.item,
                                    unit: contentMatch.unit,
                                    quantity: contentMatch.quantity,
                                },
                            ],
                            vendor: pref.item.order_preference.vendor,
                            vendor_location:
                                pref.item.order_preference.vendor_location,
                            customer: pref.item.order_preference.customer,
                            customer_location:
                                pref.item.order_preference.customer_location,
                            time: pref.item.order_preference.time,
                        });
                    }
                }
            }
        }
    );

    const { data: itemData } = useTinyItems({
        variables: { filter: { skip: 0, take: 200 } },
    });

    const { loading } = useCode({
        variables: { type: CodeType.PO },
        skip: value.po !== null,
        onCompleted: ({ code: po }) => onChange({ ...value, po }),
        fetchPolicy: 'network-only',
    });

    const { palette } = useTheme();

    const cellProps = {
        sx: {
            background: palette.tonal,
            '.MuiSelect-icon': {
                display: 'none',
            },
        },
    };

    return (
        <TableRow>
            <TableCell
                sx={{
                    ...cellProps.sx,
                    background: palette.tonal,
                }}
            >
                <Box sx={{ display: 'flex', flexFlow: 'column' }}>
                    {value.contents.map(({ item }, i) => {
                        const thisItem =
                            (itemData ? itemData.items.items : []).find(
                                (it) => it._id == item
                            ) || null;
                        return (
                            <Box
                                sx={{ display: 'flex', alignItems: 'center' }}
                                key={'line_' + index + '_' + i}
                            >
                                <Tooltip
                                    title={
                                        i === value.contents.length - 1
                                            ? 'Add item'
                                            : 'Drop item'
                                    }
                                >
                                    <IconButton
                                        size="small"
                                        onClick={() => {
                                            if (
                                                i ===
                                                value.contents.length - 1
                                            ) {
                                                // add item
                                                const copy = { ...value };
                                                copy.contents.push({
                                                    item: null,
                                                    quantity: null,
                                                    unit: null,
                                                });
                                                onChange(copy);
                                            } else {
                                                // drop item
                                                const copy = { ...value };
                                                copy.contents.splice(i, 1);
                                                onChange(copy);
                                            }
                                        }}
                                    >
                                        {i === value.contents.length - 1 ? (
                                            <BsArrow90DegDown
                                                style={{ fontSize: '.8rem' }}
                                            />
                                        ) : (
                                            <MdClear />
                                        )}
                                    </IconButton>
                                </Tooltip>
                                <Button
                                    onClick={(e) =>
                                        setItemFocus({
                                            target: e.currentTarget,
                                        })
                                    }
                                    variant="text"
                                    color="inherit"
                                    sx={{
                                        fontSize: '.9rem',
                                        fontWeight: 400,
                                        color: !thisItem
                                            ? palette.text.disabled
                                            : undefined,
                                    }}
                                >
                                    {thisItem
                                        ? thisItem.name
                                        : 'Select an item'}
                                </Button>
                                <ItemPopover
                                    onClose={() => setItemFocus(null)}
                                    target={itemFocus ? itemFocus.target : null}
                                    value={item}
                                    onChange={(it) => {
                                        const copy = { ...value };
                                        copy.contents[i].item = it;
                                        copy.contents[i].unit = null;
                                        onChange(copy);
                                    }}
                                />
                            </Box>
                        );
                    })}
                </Box>
            </TableCell>
            <TableCell
                sx={{
                    ...cellProps.sx,
                    background: palette.tonal,
                }}
            >
                <Box sx={{ display: 'flex', flexFlow: 'column' }}>
                    {value.contents.map(({ item, unit, quantity }, i) => {
                        const thisItem =
                            (itemData ? itemData.items.items : []).find(
                                (it) => it._id == item
                            ) || null;

                        return (
                            <Box
                                sx={{ display: 'flex', gap: 2, p: 0.5 }}
                                key={'line_' + index + '_' + i}
                            >
                                <TextField
                                    size="small"
                                    sx={{ maxWidth: 65 }}
                                    InputProps={{
                                        disableUnderline: true,
                                        sx: { fontSize: '.9rem' },
                                    }}
                                    variant="standard"
                                    placeholder="Qty"
                                    type="number"
                                    value={quantity || ''}
                                    onChange={(v) => {
                                        const parsed = parseFloat(
                                            v.target.value
                                        );
                                        const copy = { ...value };
                                        copy.contents[i].quantity = isNaN(
                                            parsed
                                        )
                                            ? null
                                            : parsed;
                                        onChange(copy);
                                    }}
                                />
                                <UnitField
                                    disabled={!thisItem}
                                    item_types={
                                        thisItem ? [thisItem.type] : undefined
                                    }
                                    classes={
                                        thisItem
                                            ? [thisItem.unit_class]
                                            : undefined
                                    }
                                    size="small"
                                    plural={quantity !== 1}
                                    InputProps={{
                                        disableUnderline: true,
                                        sx: {
                                            fontSize: '.9rem',
                                            minWidth: '100px',
                                        },
                                    }}
                                    variant="standard"
                                    multiple={false}
                                    value={unit || ''}
                                    onChange={(v) => {
                                        const copy = { ...value };
                                        copy.contents[i].unit = v;
                                        onChange(copy);
                                    }}
                                />
                            </Box>
                        );
                    })}
                </Box>
            </TableCell>
            <TableCell
                {...cellProps}
                sx={{
                    ...cellProps.sx,
                    background: palette.background.paper,
                }}
            >
                <Box sx={{ display: 'flex', flexFlow: 'column' }}>
                    <CompanyField
                        size="small"
                        InputProps={{
                            disableUnderline: true,
                            sx: { fontSize: '.9rem' },
                        }}
                        variant="standard"
                        multiple={false}
                        value={value.customer || ''}
                        onChange={(v) => onChange({ ...value, customer: v })}
                    />
                    {value.customer && (
                        <QueueLocationField
                            company={value.customer}
                            value={value.customer_location || null}
                            onChange={(v) =>
                                onChange({ ...value, customer_location: v })
                            }
                        />
                    )}
                </Box>
            </TableCell>
            <TableCell
                {...cellProps}
                sx={{
                    ...cellProps.sx,
                    background: palette.background.paper,
                }}
            >
                <Box sx={{ display: 'flex', flexFlow: 'column' }}>
                    <CompanyField
                        size="small"
                        InputProps={{
                            disableUnderline: true,
                            sx: { fontSize: '.9rem' },
                        }}
                        variant="standard"
                        multiple={false}
                        value={value.vendor || ''}
                        onChange={(v) => onChange({ ...value, vendor: v })}
                    />
                    {value.vendor && (
                        <QueueLocationField
                            company={value.vendor}
                            value={value.vendor_location || null}
                            onChange={(v) =>
                                onChange({ ...value, vendor_location: v })
                            }
                        />
                    )}
                </Box>
            </TableCell>
            <TableCell
                {...cellProps}
                sx={{
                    ...cellProps.sx,
                    background: palette.background.paper,
                }}
            >
                <CodeField
                    placeholder="PO#"
                    size="small"
                    InputProps={{
                        disableUnderline: true,
                        sx: { fontSize: '.9rem', maxWidth: 100 },
                    }}
                    type={CodeType.PO}
                    variant="standard"
                    value={value.po || ''}
                    onChange={(po) => {
                        onChange({ ...value, po });
                    }}
                />
            </TableCell>

            <TableCell
                {...cellProps}
                sx={{
                    ...cellProps.sx,
                    background: palette.background.paper,
                }}
            >
                <DesktopDatePicker
                    value={value.date}
                    onChange={(date) => {
                        onChange({ ...value, date });
                    }}
                    inputFormat="MM/dd/yyyy"
                    renderInput={(params) => (
                        <TextField
                            size="small"
                            variant="standard"
                            {...{
                                ...params,
                                InputProps: {
                                    ...params.InputProps,
                                    disableUnderline: true,
                                    sx: {
                                        fontSize: '.9rem',
                                        maxWidth: 120,
                                    },
                                },
                            }}
                        />
                    )}
                />
            </TableCell>
            <TableCell
                {...cellProps}
                sx={{
                    ...cellProps.sx,
                    background: palette.background.paper,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                    }}
                >
                    <Checkbox
                        color="default"
                        sx={{ marginLeft: -1 }}
                        checked={value.time !== null}
                        onChange={(e, checked) => {
                            onChange({
                                ...value,
                                time: checked ? 60 * 12 : null,
                            });
                        }}
                    />
                    {value.time !== null && (
                        <TimePicker
                            size="small"
                            variant="standard"
                            InputProps={{
                                disableUnderline: true,
                                sx: { fontSize: '.9rem' },
                            }}
                            value={value.time}
                            onChange={(time) => {
                                onChange({ ...value, time });
                            }}
                        />
                    )}
                </Box>
            </TableCell>
            <TableCell
                {...cellProps}
                sx={{
                    ...cellProps.sx,
                    background: palette.background.paper,
                }}
            >
                <IconButton
                    onClick={() => onChange(null)}
                    sx={{
                        color: palette.text.disabled,
                    }}
                >
                    <MdDelete />
                </IconButton>
            </TableCell>
        </TableRow>
    );
};

export default QueueLine;
