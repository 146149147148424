import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import VerificationBadge from '../../../../components/display/VerificationBadge';
import VerificationForm from '../../../../components/inputs/LightForms/VerificationForm';
import AppNav from '../../../../components/Layout/AppNav/components';
import NavContent from '../../../../components/Layout/AppNav/components/NavContent';
import NavHeader from '../../../../components/Layout/NavHeader';
import TabFade from '../../../../components/Layout/TabFade';
import {
    BatchQuery,
    useBatch,
} from '../../../../graphql/Batch/operations/useBatch';
import { BatchListQuery } from '../../../../graphql/Batch/operations/useBatches';
import { useBatchUpdate } from '../../../../graphql/Batch/operations/useBatchUpdate';
import { useRecipeVersion } from '../../../../graphql/RecipeVersion/operations/useRecipeVersion';
import { getStateFromBatch } from '../Mixing/components/BatchEntry';
import BatchDetailForm from './components/BatchDetailForm';

const BatchDetail = (): ReactElement => {
    const { id } = useParams();
    const { data, error, loading } = useBatch({
        variables: {
            id: id || '',
        },
    });

    const batch = data ? data.batch : null;

    const { data: recipeData, loading: recipeLoading } = useRecipeVersion({
        variables: batch ? { id: batch.recipe_version._id } : undefined,
        skip: !batch,
    });

    const recipe = recipeData ? recipeData.recipeVersion : null;

    const [handleUpdate, { loading: updateLoading, error: updateError }] =
        useBatchUpdate();

    return (
        <AppNav error={error} loading={loading || recipeLoading}>
            <NavContent>
                {{
                    header: (
                        <NavHeader
                            back={['Batch Reports', '/production/batchreports']}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1,
                                }}
                            >
                                {batch && (
                                    <VerificationBadge>
                                        {batch}
                                    </VerificationBadge>
                                )}
                                <Typography variant="crisp">Batch</Typography>
                            </Box>
                            <Box />
                        </NavHeader>
                    ),
                    content: (
                        <TabFade>
                            {{
                                Details: (
                                    <Box
                                        sx={{ paddingTop: 2, paddingBottom: 8 }}
                                    >
                                        {recipe && batch && (
                                            <BatchDetailForm
                                                recipe={recipe}
                                                batch={batch}
                                            />
                                        )}
                                    </Box>
                                ),
                                Verification: (
                                    <Box>
                                        {batch && (
                                            <Box
                                                sx={{
                                                    maxWidth: 400,
                                                    paddingTop: 3,
                                                }}
                                            >
                                                <VerificationForm
                                                    entity="batch"
                                                    verification={
                                                        batch.verification
                                                    }
                                                    loading={updateLoading}
                                                    error={updateError || null}
                                                    onSave={(v) => {
                                                        handleUpdate({
                                                            variables: {
                                                                id: batch._id,
                                                                data: {
                                                                    ...getStateFromBatch(
                                                                        batch
                                                                    ),
                                                                    verification:
                                                                        v,
                                                                },
                                                            },
                                                            refetchQueries: [
                                                                BatchQuery,
                                                                BatchListQuery,
                                                            ],
                                                        });
                                                    }}
                                                />
                                            </Box>
                                        )}
                                    </Box>
                                ),
                            }}
                        </TabFade>
                    ),
                }}
            </NavContent>
        </AppNav>
    );
};

export default BatchDetail;
