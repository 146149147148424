import { MenuItem, TextField, TextFieldProps, useTheme } from '@mui/material';
import React, { ReactElement } from 'react';
import { TinyCompany } from '../../../../../../../../graphql/Company/Company';
import {
    useCompanies,
    useTinyCompanies,
} from '../../../../../../../../graphql/Company/operations/useCompanies';
import { CompanyLocation } from '../../../../../../../../graphql/Location/Location';

export type QueueLocationFieldProps = Omit<
    TextFieldProps,
    'value' | 'onChange' | 'select' | 'children'
> & {
    value: string | null;
    onChange: (value: string | null) => void;
    company: string | null;
};

const QueueLocationField = (props: QueueLocationFieldProps): ReactElement => {
    const { palette } = useTheme();

    const {
        value,
        onChange,
        company,
        size = 'small',
        InputProps = {
            disableUnderline: true,
            sx: {
                fontSize: '.8rem',
                color: !value ? palette.text.disabled : undefined,
            },
        },
        fullWidth = true,
        variant = 'standard',
        label = undefined,
    } = props;

    const { data: companyData } = useCompanies({
        variables: {
            filter: { skip: 0, take: 250 },
        },
    });

    const companies = companyData ? companyData.companies.items : [];

    const locations: (CompanyLocation & { company: TinyCompany })[] = companies
        .map((company) => company.locations.map((loc) => ({ ...loc, company })))
        .flat();

    const validLocations = locations.filter(
        (location) => company && location.company._id == company
    );

    React.useEffect(() => {
        if (
            !company ||
            !validLocations.map((l) => l._id).includes(value || '')
        ) {
            onChange(null);
        }
    }, [value, company]);

    return (
        <TextField
            label={label}
            fullWidth={fullWidth}
            disabled={!company}
            size={size}
            InputProps={InputProps}
            variant={variant}
            select
            value={value || 'null'}
            onChange={(e) => {
                if (e.target.value == 'null') {
                    onChange(null);
                } else {
                    onChange(e.target.value);
                }
            }}
        >
            <MenuItem value="null">Any location</MenuItem>
            {validLocations.map((location) => (
                <MenuItem key={'loc_' + location._id} value={location._id}>
                    {location.label}
                </MenuItem>
            ))}
        </TextField>
    );
};

export default QueueLocationField;
