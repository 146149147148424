import { gql } from '@apollo/client';
import { LotContent } from '../Content/LotContent/LotContent';
import { FormResponse } from '../FormResponse/FormResponse';
import { TinyItem } from '../Item/Item';

export interface FulfillmentLine {
    _id: string;
    item: TinyItem;
    lots: LotContent[];
    form_response: FormResponse;
    pallet_count: number;
}

export const FulfillmentLineFragment = gql`
    fragment FulfillmentLineFragment on FulfillmentLine {
        _id
        item {
            ...TinyItemFragment
        }
        lots {
            ...LotContentFragment
        }
        form_response {
            ...FormResponseFragment
        }
        pallet_count
    }
`;
