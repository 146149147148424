import { useAuth0 } from '@auth0/auth0-react';
import Typography from '@mui/material/Typography';
import React, { ReactElement } from 'react';
import { JSONTree } from 'react-json-tree';
import AppNav from '../../../../components/Layout/AppNav/components';
import NavContent from '../../../../components/Layout/AppNav/components/NavContent';
import { useBatches } from '../../../../graphql/Batch/operations/useBatches';
import { useMixingCards } from '../../../../graphql/MixingCard/operations/useMixingCards';
import BatchEntry from './components/BatchEntry';
import MyCard from './components/MyCard';

const Mixing = (): ReactElement => {
    const { user } = useAuth0();

    const {
        data: cardData,
        error: cardError,
        loading: cardLoading,
    } = useMixingCards({
        skip: !user,
        variables: {
            filter: {
                skip: 0,
                take: 1,
                mixer: user ? user.sub : '',
            },
        },
        fetchPolicy: 'network-only',
        pollInterval: 5000,
    });

    const {
        data: batchData,
        error: batchError,
        loading: batchLoading,
    } = useBatches({
        variables: {
            filter: {
                skip: 0,
                take: 1,
                date_completed: null,
                date_abandoned: null,
                created_by: user ? user.sub : '',
            },
        },
        skip: !user || !user.sub,
        fetchPolicy: 'network-only',
    });

    const loading = cardLoading || batchLoading;
    const error = batchError || cardError;

    const cards = cardData ? cardData.mixingCardList.items : [];
    const myCard = cards[0] || null;

    return (
        <AppNav error={error} loading={loading}>
            <NavContent>
                {{
                    header: <Typography variant="crisp">Mixing</Typography>,
                    content:
                        batchData && batchData.batches.items[0] ? (
                            <BatchEntry batch={batchData.batches.items[0]} />
                        ) : (
                            <MyCard card={myCard} />
                        ),
                }}
            </NavContent>
        </AppNav>
    );
};

export default Mixing;
