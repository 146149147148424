import React from 'react';

export const useClickState = <T>(): [
    (T & { element: EventTarget & Element }) | null,
    (data: (T & { element: EventTarget & Element }) | null) => void
] => {
    const [state, setState] = React.useState<
        (T & { element: EventTarget & Element }) | null
    >(null);

    return [state, (data) => setState(data)];
};
