import { Batch } from '../../../../../../../../../../graphql/Batch/Batch';
import { RecipeVersion } from '../../../../../../../../../../graphql/RecipeVersion/RecipeVersion';

export const useGate = (batch: Batch, recipe: RecipeVersion): number => {
    let gate: number | null = null;

    recipe.sections.map((section, sectionIndex) => {
        if (gate == null) {
            section.steps.map((s) => {
                if (s.content) {
                    const lots = batch.lines.filter(
                        (l) => l.recipe_step == s._id
                    );

                    if (lots.length == 0) gate = sectionIndex;
                }
            });
        }
    });

    return gate == null ? recipe.sections.length : gate;
};

export const isSectionComplete = (
    section_index: number,
    gate: number,
    batch: Batch,
    recipe: RecipeVersion
): boolean => {
    // if (gate <= section_index) return false;
    const section = recipe.sections[section_index];

    let complete = true;

    section.steps.map((step, index) => {
        if (!step.content) complete = true;
        else if (complete == true) {
            const applicable = batch.lines.filter(
                (l) => l.recipe_step == step._id
            );

            if (applicable.length == 0) complete = false;
        }
    });

    return complete;
};
