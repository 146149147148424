import { DesktopDatePicker } from '@mui/lab';
import { Box, Button, TextField, Typography, useTheme } from '@mui/material';
import React, { ReactElement } from 'react';
import CompanyField from '../../../../../../../../../../../../../../../../components/inputs/FieldInputs/CompanyField';
import { AppointmentInput } from '../../../../../../../../../../../../../../../../graphql/Appointment/AppointmentInput';
import { useTinyItems } from '../../../../../../../../../../../../../../../../graphql/Item/operations/useTinyItems';
import { useClickState } from '../../../../../../../../../../../../../../../../hooks/useClickState';
import QueueLocationField from '../../../../../../../../../../../../../OrderQueue/components/QueueLine/components/QueueLocationField';

const AppointmentForm = (props: {
    mode: 'from' | 'to';
    value: AppointmentInput;
    onChange: (value: AppointmentInput) => void;
}): ReactElement => {
    const { mode, value, onChange } = props;

    const { palette } = useTheme();

    return (
        <Box
            sx={{
                display: 'flex',
                flexFlow: 'column',
                alignItems: 'flex-start',
            }}
        >
            <Typography color="textSecondary" variant="caption">
                {mode == 'from' ? 'From' : 'To'}
            </Typography>
            <CompanyField
                variant="standard"
                InputProps={{ disableUnderline: true }}
                multiple={false}
                value={value.company || ''}
                onChange={(e) => onChange({ ...value, company: e || null })}
            />
            <QueueLocationField
                company={value.company}
                variant="standard"
                InputProps={{
                    disableUnderline: true,
                    sx:
                        !value.company || !value.location
                            ? {
                                  color: palette.text.disabled,
                                  fontSize: '.9rem',
                              }
                            : {
                                  color: palette.text.secondary,
                                  fontSize: '.9rem',
                              },
                }}
                value={value.location || ''}
                onChange={(e) => onChange({ ...value, location: e || null })}
            />
            <DesktopDatePicker
                value={value.date}
                onChange={(date) => {
                    onChange({ ...value, date });
                }}
                inputFormat="MM/dd/yyyy"
                renderInput={(params) => (
                    <TextField
                        size="small"
                        variant="standard"
                        {...{
                            ...params,
                            InputProps: {
                                ...params.InputProps,
                                disableUnderline: true,
                                sx: {
                                    fontSize: '.9rem',
                                    color: palette.text.secondary,
                                    maxWidth: 120,
                                },
                            },
                        }}
                    />
                )}
            />
        </Box>
    );
};

export default AppointmentForm;
