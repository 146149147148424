import { gql } from '@apollo/client';
import { Profile } from '../../auth/User';
import { UserRole } from '../../auth/UserRole';
import { ProfileFragment } from '../Profile/useProfile';
import { getMutationHook } from '../types';

export const CreateProfile = gql`
    ${ProfileFragment}
    mutation CreateProfile($data: CreateProfileInput!) {
        createProfile(data: $data) {
            ...ProfileFragment
        }
    }
`;

interface CreateProfileInputBase {
    role: UserRole;
    given_name: string;
    family_name: string;
    temporary_password: string;
}

export type CreateProfileInput = CreateProfileInputBase &
    ({ email: string } | { username: string });

export interface CreateProfileRes {
    createProfile: Profile;
}

export interface CreateProfileArgs {
    data: CreateProfileInput;
}

export const useProfileCreation = getMutationHook<
    CreateProfileRes,
    CreateProfileArgs
>(CreateProfile);
