import { Button } from '@mui/material';
import { Box } from '@mui/system';
import React, { ReactElement } from 'react';
import { MdChevronLeft } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

export interface NavHeaderProps {
    children: [ReactElement, ReactElement];
    back?: [string, string];
}

const NavHeader = (props: NavHeaderProps): ReactElement => {
    const { children, back } = props;
    const nav = useNavigate();

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'space-between',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexFlow: 'column',
                    gap: 1,
                    alignItems: 'flex-start',
                }}
            >
                {back && (
                    <Button
                        color="inherit"
                        onClick={() => nav(back[1])}
                        startIcon={<MdChevronLeft />}
                        variant="text"
                    >
                        {back[0]}
                    </Button>
                )}
                {children[0]}
            </Box>
            <Box>{children[1]}</Box>
        </Box>
    );
};

export default NavHeader;
