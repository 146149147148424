import {
    ToggleButton,
    ToggleButtonGroup,
    ToggleButtonGroupProps,
} from '@mui/lab';
import { ButtonGroupProps } from '@mui/material/ButtonGroup';
import React, { ReactElement } from 'react';
import { useLanguageContext } from '../../../providers/LanguageProvider';

export type SpanishToggleProps = Omit<
    ToggleButtonGroupProps,
    'value' | 'onChange'
>;

const SpanishToggle = (props: SpanishToggleProps): ReactElement => {
    const toggleProps = { ...props };
    const { preference, setPreference } = useLanguageContext();

    return (
        <ToggleButtonGroup value={preference} {...toggleProps}>
            <ToggleButton
                sx={{
                    padding: 1,
                    paddingLeft: 2,
                    paddingRight: 2,
                    textTransform: 'none',
                }}
                value="en"
                onClick={() => setPreference('en')}
            >
                English
            </ToggleButton>
            <ToggleButton
                sx={{
                    padding: 1,
                    paddingLeft: 2,
                    paddingRight: 2,
                    textTransform: 'none',
                }}
                value="sp"
                onClick={() => setPreference('sp')}
            >
                Spanish
            </ToggleButton>
        </ToggleButtonGroup>
    );
};

export default SpanishToggle;
