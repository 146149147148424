import React, { ReactElement } from 'react';
import { Batch } from '../../../../../../../../graphql/Batch/Batch';
import { RecipeVersion } from '../../../../../../../../graphql/RecipeVersion/RecipeVersion';
import {
    Box,
    Button,
    ToggleButton,
    ToggleButtonGroup,
    useTheme,
} from '@mui/material';
import Spanish from '../../../../../../../../components/display/Spanish';
import SpanishToggle from '../../../../../../../../components/inputs/SpanishToggle';
import BatchContent from './components/BatchContent';
import { MdExitToApp } from 'react-icons/md';
import ThemeToggle from '../../../../../../../../components/inputs/ThemeToggle';
import { useNavigate } from 'react-router-dom';

export interface BatchRecipeProps {
    batch: Batch;
    recipe: RecipeVersion;
}

const BatchRecipe = (props: BatchRecipeProps): ReactElement => {
    const { batch, recipe } = props;

    const nav = useNavigate();

    const { palette, shadows, shape } = useTheme();

    return (
        <Box
            sx={{
                height: '100%',
                display: 'flex',
                flexFlow: 'column',
            }}
        >
            <Box
                sx={{
                    p: 1.5,
                    background: palette.background.paper,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    boxShadow: shadows[4],
                    zIndex: 10,
                }}
            >
                <Spanish
                    variant="crisp"
                    sx={{ fontSize: '1.7rem', paddingLeft: 1 }}
                >
                    Mixing
                </Spanish>
                <Box sx={{ display: 'flex', alignItems: 'stretch', gap: 2 }}>
                    <Box>
                        <ThemeToggle />
                    </Box>
                    <SpanishToggle />
                    <Button
                        onClick={() => nav('/production/mixing')}
                        endIcon={<MdExitToApp />}
                        color="inherit"
                    >
                        Exit batch
                    </Button>
                </Box>
            </Box>
            <Box
                sx={{
                    flex: 1,
                    overflow: 'auto',
                    display: 'flex',
                    flexFlow: 'column',
                    alignItems: 'center',
                }}
            >
                <Box p={3} />
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                    }}
                >
                    <BatchContent {...props} />
                </Box>
                <Box p={10} />
            </Box>
        </Box>
    );
};

export default BatchRecipe;
