import { gql } from '@apollo/client';
import { Base } from '../Base/Base';

export interface PackingSessionLot {
    _id: string;
    code: string;
    item: {
        _id: string;
        name: string;
        spanish_name: string;
    };
    company: {
        _id: string;
        name: string;
    };
    date_created: Date;
}

export const PackingSessionLotFragment = gql`
    fragment PackingSessionLotFragment on Lot {
        _id
        code
        item {
            _id
            name
            spanish_name
        }
        company {
            _id
            name
        }
        date_created
    }
`;

export interface PackingSession extends Base {
    date_completed: Date | null;
    product: { _id: string; name: string };
    location: { _id: string; label: string };
    production_lines: { _id: string; name: string }[];
    use_trays: boolean;
    contents: {
        lot: PackingSessionLot;
        client_unit: { _id: string };
        client_qty: number;
        receipt_line: string | null;
    }[];
    client_contents: {
        lot: PackingSessionLot;
        client_unit: { _id: string };
        client_qty: number;
        receipt_line: string | null;
    }[];
    lots: PackingSessionLot[];
}

export const PackingSessionFragment = gql`
    fragment PackingSessionFragment on PackingSession {
        ...BaseFragment
        contents {
            lot {
                ...PackingSessionLotFragment
            }
            client_unit {
                _id
            }
            client_qty
            receipt_line
        }
        client_contents {
            lot {
                ...PackingSessionLotFragment
            }
            client_unit {
                _id
            }
            client_qty
            receipt_line
        }
        lots {
            ...PackingSessionLotFragment
        }
        date_completed
        product {
            _id
            name
        }
        location {
            _id
            label
        }
        production_lines {
            _id
            name
        }
        use_trays
    }
`;
