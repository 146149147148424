import { Box, Button, Typography } from '@mui/material/';
import React, { ReactElement } from 'react';
import { MdOutlineArrowForward } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import CarefullButton from '../../../../../../../../components/inputs/Buttons/CarefulButton';
import { CreatePackingSessionInput } from '../../../../../../../../graphql/PackingSession/inputs/CreatePackingSessionInput';
import { UpdatePackingSessionInput } from '../../../../../../../../graphql/PackingSession/inputs/UpdatePackingSessionInput';
import { PackingSessionQuery } from '../../../../../../../../graphql/PackingSession/operations/usePackingSession';
import { PackingSessionsQuery } from '../../../../../../../../graphql/PackingSession/operations/usePackingSessions';
import { usePackingSessionUpdate } from '../../../../../../../../graphql/PackingSession/operations/usePackingSessionUpdate';
import { PackingSession } from '../../../../../../../../graphql/PackingSession/PackingSession';
import { useProfileContext } from '../../../../../../../../providers/ProfileProvider';

export interface SessionPreviewProps {
    children: PackingSession;
}

export const getStateFromSession = (
    session: PackingSession
): UpdatePackingSessionInput | CreatePackingSessionInput => ({
    date_completed: session.date_completed,
    product: session.product._id,
    location: session.location._id,
    production_lines: session.production_lines.map((p) => p._id),
    use_trays: session.use_trays,
    client_contents: session.client_contents.map((c) => ({
        lot: c.lot._id,
        unit: c.client_unit._id,
        quantity: c.client_qty,
        receipt_line: c.receipt_line,
    })),
});

const SessionPreview = (props: SessionPreviewProps): ReactElement => {
    const { children: session } = props;
    const nav = useNavigate();

    const { data: profileData } = useProfileContext();

    const [handleUpdate, { loading: updateLoading }] =
        usePackingSessionUpdate();

    const abandon = () =>
        handleUpdate({
            variables: {
                id: session._id,
                data: {
                    ...getStateFromSession(session),
                    date_completed: new Date(),
                },
            },
            refetchQueries: [PackingSessionQuery, PackingSessionsQuery],
        });

    return (
        <Box
            sx={{
                height: '100%',
                display: 'flex',
                flexFlow: 'column',
                justifyContent: 'center',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexFlow: 'column',
                    alignItems: 'center',
                    gap: 1,
                }}
            >
                <Typography>{`Hey ${
                    profileData
                        ? profileData.profile.given_name ||
                          profileData.profile.name
                        : ''
                },`}</Typography>
                <Typography variant="h4">
                    {`Let's finish this Packing Session`}
                </Typography>
                <Box p={1} />
                <Button
                    onClick={() => nav(session._id)}
                    endIcon={<MdOutlineArrowForward />}
                >{`Continue ${session.product.name} Session`}</Button>
                <Box p={6} />
                <Typography>or</Typography>
                <CarefullButton
                    loading={updateLoading}
                    onClick={() => {
                        abandon();
                    }}
                >
                    Close this session
                </CarefullButton>
            </Box>
        </Box>
    );
};

export default SessionPreview;
