import { gql } from '@apollo/client';
import { BatchLineFragment } from '../../BatchLine/BatchLine';
import { getMutationHook } from '../../types';
import { TinyUnitFragment } from '../../Unit/Unit';
import { VerificationFragment } from '../../Verification/Verification';
import { Batch, BatchFragment } from '../Batch';

export const LaunchBatch = gql`
    ${BatchFragment}
    ${BatchLineFragment}
    ${TinyUnitFragment}
    ${VerificationFragment}
    mutation LaunchBatch {
        startBatch {
            ...BatchFragment
        }
    }
`;

export const useBatchLaunch = getMutationHook<{ startBatch: Batch }>(
    LaunchBatch
);
