import React, { ReactElement } from 'react';
import { Box, ButtonGroup, Typography, useTheme } from '@mui/material/';
import { PackingSession } from '../../../../../../../../../../graphql/PackingSession/PackingSession';
import { useSnackbar } from '../../../../../../../../../../providers/SnackbarProvider';
import {
    ScanLot,
    useScanLots,
} from '../../../../../../../../../../graphql/Scan/useScanLots';
import { ScanType } from '../../../../../../../../../../graphql/Scan/ScanType';
import {
    UpdatePackingSessionArgs,
    usePackingSessionUpdate,
} from '../../../../../../../../../../graphql/PackingSession/operations/usePackingSessionUpdate';
import { getStateFromSession } from '../../../../../PalletizingLanding/components/SessionPreview';
import { useScanner } from '../../../../../../../../../../hooks/useScanner';
import ResponsiveDialog from '../../../../../../../../../../components/Layout/ResponsiveDialog';
import { LoadingButton } from '@mui/lab';
import { MdChevronRight } from 'react-icons/md';

export interface SessionContentScanProps {
    session: PackingSession;
}

const SessionContentScan = (props: SessionContentScanProps): ReactElement => {
    const { session } = props;
    const { setSnackbar } = useSnackbar();
    const { palette } = useTheme();

    const [prompt, setPrompt] = React.useState<{
        lots: ScanLot[];
        type: ScanType;
        identifier: string;
    } | null>(null);

    const alert = (error: Error) => {
        setSnackbar({
            open: true,
            variant: 'error',
            message: error.message,
        });
    };

    const [updateSession, { loading: sessionLoading }] =
        usePackingSessionUpdate({
            onError: (err) => {
                alert(err);
            },
            fetchPolicy: 'network-only',
        });

    const handleUpdate = (data: UpdatePackingSessionArgs['data']) => {
        updateSession({
            variables: {
                id: session._id,
                data,
            },
        });
    };

    const handleLotEntry = (
        lot: ScanLot,
        type: ScanType,
        identifider: string
    ) => {
        const existing = session.client_contents.filter(
            (l) => l.lot._id == lot._id
        );
        if (existing.length > 0)
            alert(new Error(`Lot is already included in session.`));
        else {
            const data = getStateFromSession(session);
            console.log(data.client_contents);
            data.client_contents.push({
                lot: lot._id,
                quantity: 0,
                unit: null,
                receipt_line: type == ScanType.ReceiptLine ? identifider : null,
            });
            console.log(data.client_contents);
            handleUpdate(data);
        }
    };

    const handleLotsResponse = (data: {
        lots: ScanLot[];
        type: ScanType;
        identifier: string;
    }) => {
        if (data.lots.length == 0) alert(new Error('No lots found.'));
        else if (data.lots.length > 1) {
            setPrompt(data);
        } else {
            const lot = data.lots[0];
            handleLotEntry(lot, data.type, data.identifier);
        }
    };

    const [handleScan, { loading: scanLoading }] = useScanLots({
        onComplete: (data) => {
            if (!sessionLoading) handleLotsResponse(data);
        },
        onError: (error) => {
            alert(error);
        },
    });

    useScanner({
        onScan: (code) => {
            handleScan(code.toString());
        },
    });

    const loading = scanLoading || sessionLoading;

    return (
        <React.Fragment>
            <ResponsiveDialog
                PaperProps={{ sx: { background: palette.background.default } }}
                open={Boolean(prompt)}
                onClose={() => setPrompt(null)}
            >
                <Typography variant="h6">Which lot did you scan?</Typography>
                <Box p={1} />
                <ButtonGroup
                    size="large"
                    variant="contained"
                    fullWidth
                    orientation="vertical"
                >
                    {(prompt ? prompt.lots : []).map((lot, index) => (
                        <LoadingButton
                            loading={loading}
                            variant="contained"
                            color="success"
                            endIcon={<MdChevronRight />}
                            key={'prompt_ ' + index}
                            onClick={() => {
                                if (prompt) {
                                    handleLotEntry(
                                        lot,
                                        prompt.type,
                                        prompt.identifier
                                    );
                                    setPrompt(null);
                                }
                            }}
                        >
                            {lot.code}
                        </LoadingButton>
                    ))}
                </ButtonGroup>
            </ResponsiveDialog>
        </React.Fragment>
    );
};

export default SessionContentScan;
