import { Typography, useTheme } from '@mui/material/';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import React, { ReactElement } from 'react';
import { FaPallet } from 'react-icons/fa';
import Spanish from '../../../../../../../../components/display/Spanish';
import LotDocument from '../../../../../../../../components/Documents/LotDocument';
import { PackingSessionQuery } from '../../../../../../../../graphql/PackingSession/operations/usePackingSession';
import { usePalletCreation } from '../../../../../../../../graphql/PackingSession/operations/usePalletCreation';
import { PackingSession } from '../../../../../../../../graphql/PackingSession/PackingSession';
import { useSnackbar } from '../../../../../../../../providers/SnackbarProvider';

export interface SessionActionProps {
    session: PackingSession;
}

const SessionAction = (props: SessionActionProps): ReactElement => {
    const { session } = props;

    const { palette, shape } = useTheme();

    const { setSnackbar } = useSnackbar();

    const [createPallet, { loading }] = usePalletCreation({
        onError: (error) =>
            setSnackbar({
                variant: 'error',
                message: error.message,
                open: true,
            }),
        refetchQueries: [PackingSessionQuery, PackingSessionQuery],
        variables: {
            id: session._id,
        },
    });

    return (
        <Box
            sx={{
                overflow: 'auto',
                flex: 1,
                display: 'flex',
                flexFlow: 'column',
                textAlign: 'center',
                gap: 2,
                paddingTop: 10,
                paddingBottom: 10,
            }}
        >
            <Box>
                <ButtonBase
                    disabled={loading}
                    sx={{
                        ...shape,
                        padding: 2,
                        border: `2px solid ${palette.text.primary}`,
                    }}
                    onClick={() => createPallet()}
                >
                    <Box sx={{ padding: 2, fontSize: '2rem', display: 'flex' }}>
                        <FaPallet />
                    </Box>
                    <Box>
                        <Spanish variant="h4">New Pallet</Spanish>
                    </Box>
                </ButtonBase>
            </Box>
            {[...session.lots]
                .sort((a, b) => {
                    return (
                        new Date(b.date_created).getTime() -
                        new Date(a.date_created).getTime()
                    );
                })
                .map((lot, index) => (
                    <LotDocument
                        overlaySx={
                            index == 0
                                ? {
                                      border: `6px solid ${palette.primary.main}`,
                                      borderRadius: 2,
                                  }
                                : undefined
                        }
                        id={lot._id}
                        key={'label_' + lot._id}
                    />
                ))}
        </Box>
    );
};

export default SessionAction;
